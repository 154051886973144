import BreadCrumbs from 'src/components/ui/shared/breadCrumbs/BreadCrumbs';
import Title from 'src/components/ui/shared/title/Title';
import styles from './descriptionService.module.scss';

interface Props {
  title: string;
  text: string;
}

const DescriptionService = ({ title, text }: Props) => {
  return (
    <section className={styles.currentServiceDescription}>
      <BreadCrumbs type='services' className={styles.breadCrumbs} />
      <Title title={title} />
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </section>
  );
};
export default DescriptionService;
