import AdvantagesBounds from './advantagesBounds/AdvantagesBounds';
import FunctionBounds from './functionBounds/FunctionBounds';
import WhereBounds from './whereBounds/WhereBounds';

const BoundsComponent = () => {
  return (
    <>
      <WhereBounds />
      <FunctionBounds />
      <AdvantagesBounds />
    </>
  );
};
export default BoundsComponent;
