import CurrentService from 'src/components/services/currentService/CurrentServices';
import DescriptionService from 'src/components/services/shared/descriptionService/DescriptionService';
import Container from 'src/components/ui/shared/container/Container';
import KeyContacts from 'src/components/ui/widgets/keyContacts/KeyContacts';
import styles from './services.module.scss';
import NewsBlock from 'src/components/ui/widgets/newsBlock/NewsBlock';
import ContainerFlex from 'src/components/ui/shared/containerFlex/ContainerFlex';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { getCategoryAllServicesThunk } from 'src/redux/categoriesServices/thunk';
import { useTranslation } from 'react-i18next';
import { getNewsThunk } from 'src/redux/news/thunks';
import { setBreadCrumbs } from 'src/redux/categoriesServices';
import routers from 'src/navigation/routers';
import { Helmet } from 'react-helmet-async';

const Service = () => {
  const params = useParams<{ idService: string }>();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    dispatch(getNewsThunk({ is_event: 0, page: 1 }));
    if (params?.idService) {
      dispatch(getCategoryAllServicesThunk(params.idService));
    }
  }, [dispatch, i18n.language, params.idService]);

  const { categoryServices, news } = useAppSelector((state) => ({
    categoryServices: state.categoriesServices.categoryServices,
    news: state.news.news?.results,
  }));

  useEffect(() => {
    if (categoryServices) {
      const links = [
        {
          title: t('navigationLinks.services'),
          path: routers.services,
        },
        {
          title: categoryServices?.name,
          path: `${routers.services}/${categoryServices.id}`,
        },
      ];

      dispatch(setBreadCrumbs(links));
    }
  }, [categoryServices, dispatch, t]);

  return (
    <>
      <Helmet>
        <title>{t('pageTitles.services')}</title>
      </Helmet>
      <DescriptionService
        text={categoryServices?.description || ''}
        title={categoryServices?.name || ''}
      />
      <Container isPaddingTop={false}>
        <ContainerFlex
          panelLeft={
            <CurrentService serviceSet={categoryServices?.service_set} />
          }
          panelRight={
            <>
              {categoryServices &&
                categoryServices?.key_contacts?.length > 0 && (
                  <KeyContacts contacts={categoryServices?.key_contacts} />
                )}
              {news && <NewsBlock className={styles.newsService} news={news} />}
            </>
          }
        />
      </Container>
    </>
  );
};
export default Service;
