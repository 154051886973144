import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BASE_URL } from 'src/axios/baseUrl';
import Button from 'src/components/ui/shared/button/Button';
import { useFormattedDate } from 'src/hooks';
import { TCommentItem, TEventItem } from 'src/types/news';
import { TReview } from 'src/types/review';
import styles from './newsContent.module.scss';
import LinkWithIcon from 'src/components/ui/shared/linkWithIcon/LinkWithIcon';
interface IProps {
  currentNews?: TCommentItem | TEventItem | TReview;
}

const NewsContent = ({ currentNews }: IProps) => {
  const { t } = useTranslation('press-center');
  const { format } = useFormattedDate();

  function isFile(
    item: TCommentItem | TEventItem | TReview,
  ): item is TEventItem | TReview {
    return 'file' in item;
  }

  function isMediaUrl(
    item: TCommentItem | TEventItem | TReview,
  ): item is TCommentItem {
    return 'media_url' in item;
  }

  function isMedia(
    item: TCommentItem | TEventItem | TReview,
  ): item is TCommentItem | TEventItem {
    return 'media_text' in item;
  }

  function isComment(
    item: TCommentItem | TEventItem | TReview,
  ): item is TCommentItem {
    return 'media_comment' in item;
  }

  const hasFile =
    currentNews?.file ||
    (currentNews && !isFile(currentNews) && currentNews?.media_url);

  return (
    <article className={styles.root}>
      <h1 className={styles.title}>{currentNews?.name}</h1>
      <p className={styles.articleInfo}>
        {currentNews?.publication_date && format(currentNews?.publication_date)}
      </p>
      {currentNews && (
        <div
          className={styles.mainContent}
          dangerouslySetInnerHTML={{
            __html: isMedia(currentNews)
              ? currentNews?.media_text
              : currentNews?.text,
          }}
        />
      )}
      {currentNews && isComment(currentNews) && (
        <div className={styles.commentWrapper}>
          <div
            dangerouslySetInnerHTML={{
              __html: isComment(currentNews) ? currentNews?.media_comment : '',
            }}
          />
        </div>
      )}
      {currentNews?.link !== undefined && currentNews?.link?.length > 0 && (
        <div className={styles.commentGo}>
          <LinkWithIcon
            path={currentNews?.link || ''}
            text={currentNews?.link_name || t('full')}
          />
        </div>
      )}

      {currentNews && hasFile && (
        <div className={styles.buttonWrapper}>
          {isFile(currentNews) && (
            <Link
              target='_blank'
              to={`${BASE_URL}${currentNews?.file?.url || ''}`}
            >
              {currentNews?.file !== null && <Button>{t('pdfButton')}</Button>}
            </Link>
          )}
          {!isFile(currentNews) && (
            <Link target='_blank' to={currentNews?.media_url}>
              <Button>{t('pdfButton')}1</Button>
            </Link>
          )}
          {isMediaUrl(currentNews) && (
            <div className={styles.commentFullText}>
              <LinkWithIcon
                path={currentNews?.media_url}
                text={currentNews?.link_name || t('full')}
              />
            </div>
          )}
        </div>
      )}
    </article>
  );
};

export default NewsContent;
