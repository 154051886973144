import { SwiperSlide } from 'swiper/react';
import styles from './employeeSlider.module.scss';
import Slider from 'src/components/ui/shared/slider/Slider';
import { TImageSetItem } from 'src/types/shared';
import { BASE_URL } from 'src/axios/baseUrl';

interface Props {
  images?: TImageSetItem[];
}

const EmployeeSlider = ({ images }: Props) => {
  return (
    <div className={styles.employeeSliderBlock}>
      <Slider>
        {images?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className={styles.employeeSlider}>
              <img src={`${BASE_URL}${item?.image_file?.url}` || ''} />
            </div>
          </SwiperSlide>
        ))}
      </Slider>
    </div>
  );
};
export default EmployeeSlider;
