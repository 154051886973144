import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AllCardContainer from 'src/components/ui/shared/allCardContainer/AllCardContainer';
import CardInfo from 'src/components/ui/shared/cardInfo/CardInfo';
import { useFormattedDate } from 'src/hooks';
import routers from 'src/navigation/routers';
import { TReview } from 'src/types/review';
import styles from './review.module.scss';

interface IProps {
  cardsReview: TReview[];
  textCardFooter?: string;
  isPreview?: boolean;
}

const CardsReview = ({ cardsReview, textCardFooter, isPreview }: IProps) => {
  const { format } = useFormattedDate();
  const [cardsPreview, setCardsPreview] = useState<TReview[]>([]);

  useEffect(() => {
    if (isPreview) {
      if (cardsReview?.length > 6) {
        const result = [];
        for (let i = cardsReview?.length - 6; i < cardsReview?.length; i++) {
          result.push(cardsReview[i]);
        }
        setCardsPreview(result);
      } else {
        setCardsPreview(cardsReview);
      }
    } else {
      setCardsPreview(cardsReview);
    }
  }, [cardsReview, isPreview]);

  return (
    <AllCardContainer>
      {cardsPreview?.map((card) => {
        return (
          <li key={card.id}>
            <Link to={`${routers.analytics}/review/${card.id}`} target='_self'>
              <CardInfo
                title={card?.name}
                key={card.id}
                textFooter={
                  textCardFooter
                    ? `${card.category.name} | ${textCardFooter}`
                    : ''
                }
              >
                <div className={styles.headCard}>
                  <p className={`${styles.link}`}>
                    {format(card?.publication_date)}
                  </p>
                </div>
              </CardInfo>
            </Link>
          </li>
        );
      })}
    </AllCardContainer>
  );
};
export default CardsReview;
