import { useState } from 'react';
import Calendar from 'react-calendar';
import styles from './calendar.module.scss';
import 'react-calendar/dist/Calendar.css';
import { ReactComponent as CalendarIcon } from 'src/assets/images/icons/calendar.svg';
import { useTranslation } from 'react-i18next';

interface IProps {
  date?: Date;
  setDate: (value: Date) => void;
}

const MainCalendar = ({ date = new Date(), setDate }: IProps) => {
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const { i18n } = useTranslation();

  const year = date.getFullYear();
  const month = date.toLocaleString(`${i18n.language}`, {
    month: 'long',
  });

  const onChange = (date: Date) => {
    setDate(date);
    setIsOpenCalendar(false);
  };

  return (
    <div className={styles.calendarBlock}>
      <button
        className={styles.calendarTitle}
        onClick={() => setIsOpenCalendar(!isOpenCalendar)}
      >
        <CalendarIcon />
        <p className={styles.title}>
          {month}&nbsp;
          {year}
        </p>
      </button>
      <div
        className={
          isOpenCalendar
            ? `${styles.calendar} ${styles.calendarView}`
            : styles.calendar
        }
      >
        <Calendar
          prev2Label={null}
          next2Label={null}
          value={date}
          view='year'
          onClickMonth={onChange}
          locale={i18n.language}
        />
      </div>
    </div>
  );
};
export default MainCalendar;
