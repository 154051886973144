import { useTranslation } from 'react-i18next';
import Telegram from '../../../../assets/images/socialIcon/TelegramColor.svg';
import Whatsapp from '../../../../assets/images/socialIcon/Whatsapp.svg';
import Messenger from '../../../../assets/images/socialIcon/MessengerIcon.svg';
import Logo from '../../../../assets/images/logo/logoImages.svg';
import routers from '../../../../navigation/routers';
import MainLink from '../../shared/link/MainLink';
import LinksSocial from '../../shared/entities/linksSocial/LinksSocial';
import Accordion from '../../shared/accordion/Accordion';
import styles from './footer.module.scss';
import FooterBottom from './footerBottom/FooterBottom';
import { useEffect } from 'react';
import { getAllCategoriesThunk } from 'src/redux/categoriesServices/thunk';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { getContactsThunk } from 'src/redux/contacts/thunk';
import { setRuContacts } from 'src/redux/contacts';

const Footer = () => {
  const { t, i18n } = useTranslation('common');

  const dispatch = useAppDispatch();

  const { categories, contacts, ruContact } = useAppSelector((state) => ({
    categories: state.categoriesServices.categories,
    contacts: state.contacts.contacts,
    ruContact: state.contacts.ruContacts,
  }));

  useEffect(() => {
    dispatch(getAllCategoriesThunk());
    dispatch(getContactsThunk());
  }, [dispatch, i18n.language]);

  useEffect(() => {
    if (contacts.length > 0) {
      const ruContact = contacts.find(
        (item) => item.title === t('ru') || item.title === 'Russia',
      );

      ruContact && dispatch(setRuContacts(ruContact));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts, dispatch]);

  const LINKSSHARED = [
    {
      title: t('navigationLinks.team'),
      path: routers.team,
    },
    {
      title: t('navigationLinks.aboutUs'),
      path: routers.company,
    },
    {
      title: t('navigationLinks.services'),
      path: routers.services,
    },
    {
      title: t('navigationLinks.pressCenter'),
      path: routers.pressCenter,
    },
    {
      title: t('navigationLinks.analytics'),
      path: routers.analytics,
    },
    {
      title: t('navigationLinks.contacts'),
      path: routers.contact,
    },
    {
      title: t('navigationLinks.infoForBondholders'),
      path: routers.informationBonds,
    },
  ];

  if (i18n.language === 'ru') {
    LINKSSHARED.push(
      {
        title: t('navigationLinks.assetsNews'),
        path: routers.assetsUnlocking,
      },
      {
        title: t('navigationLinks.newsLawsuit'),
        path: routers.lawsuit,
      },
    );
  }

  const LINKSOCIAL = [
    {
      path: ruContact?.contacts_set[0]?.telegram || '',
      icon: Telegram,
      alt: 'Telegram',
      title: '',
    },
    {
      path: ruContact?.contacts_set[0]?.whatsapp || '',
      icon: Whatsapp,
      alt: 'Whatsapp',
      title: '',
    },
    {
      path: ruContact?.contacts_set[0]?.messenger || '',
      icon: Messenger,
      alt: 'Carrer',
      title: '',
    },
  ];

  const bodyServices = () => (
    <div className={styles.footerItemList}>
      {categories?.map((item) => (
        <div key={item.id} className={styles.footerLinkTitle}>
          <MainLink
            target='_parent'
            path={`${routers.services}/${item.id}`}
            key={item.id}
          >
            <span>{item.name}</span>
          </MainLink>
        </div>
      ))}
    </div>
  );

  const bodyGeneral = () => (
    <div className={styles.footerItemList}>
      {LINKSSHARED?.map((item) => (
        <div key={item.title} className={styles.footerLinkTitle}>
          <MainLink path={item.path}>
            <span>{item.title}</span>
          </MainLink>
        </div>
      ))}
    </div>
  );

  const bodyContact = () => {
    const phoneNumber = ruContact?.contacts_set[0]?.phone?.replace(
      /[^+\d]/g,
      '',
    );

    return (
      <div className={styles.footerItemList}>
        {ruContact && ruContact?.contacts_set[0]?.address?.length > 0 ? (
          <p>{ruContact?.contacts_set[0]?.address}</p>
        ) : (
          <>
            <p>{t('contactInfo.one')}</p>
            <p>{t('contactInfo.two')}</p>
            <p>{t('contactInfo.three')}</p>
            <p>{t('contactInfo.four')}</p>
          </>
        )}
        <div className={styles.contactsBlock}>
          <p>
            {t('contactInfo.number')}:
            <a href={`tel:${phoneNumber}`}>
              <span>{ruContact?.contacts_set[0]?.phone}</span>
            </a>
          </p>
          <p>
            {t('contactInfo.email')}:
            <a href={`mailto:${ruContact?.contacts_set[0]?.email}`}>
              <span>{ruContact?.contacts_set[0]?.email}</span>
            </a>
          </p>
        </div>

        <div className={styles.contactsSocial}>
          <LinksSocial links={LINKSOCIAL} />
        </div>
      </div>
    );
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footerMain}>
        <div className={styles.footerItemImage}>
          <MainLink path={routers.home}>
            <div className={styles.footerLogo}>
              <img src={Logo} alt='nektorov, saveliev' />
            </div>
          </MainLink>
        </div>
        <div className={styles.footerItem}>
          <h5>{t('services')}</h5>
          {bodyServices()}
        </div>
        <div className={styles.footerItem}>
          <h5>{t('generalInformation')}</h5>
          {bodyGeneral()}
        </div>
        <div className={styles.footerItem}>
          <h5>{t('contactInformation')}</h5>
          {bodyContact()}
        </div>
        <div className={styles.accordion}>
          <Accordion countList={categories.length} title={t('services')}>
            {bodyServices()}
          </Accordion>
        </div>
        <div className={styles.accordion}>
          <Accordion
            countList={LINKSSHARED.length}
            title={t('generalInformation')}
          >
            {bodyGeneral()}
          </Accordion>
        </div>
        <div className={styles.accordion}>
          <Accordion title={t('contactInformation')}>{bodyContact()}</Accordion>
        </div>
      </div>
      <FooterBottom />
    </footer>
  );
};

export default Footer;
