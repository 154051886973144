import { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import isEmailValid from '../../../utils/isValidEmail';
import Button from '../../ui/shared/button/Button';
import Checkbox from '../../ui/shared/checkbox/Checkbox';
import Input from '../../ui/shared/input/Input';
import MainLink from '../../ui/shared/link/MainLink';
import Modal from '../../ui/shared/modal/Modal';
import Textarea from '../../ui/shared/textarea/Textarea';
import TitleSection from '../../ui/shared/titleSection/TitleSection';
import styles from './askLawyer.module.scss';
import PersonalData from 'src/assets/politicsDocument/legal.pdf';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { sendAskFormThunk } from 'src/redux/askForm/thunk';
import Loader from 'src/components/ui/shared/loader/Loader';
interface Props {
  title?: string;
}

const AskLawyer = ({ title }: Props) => {
  const { t } = useTranslation(['team', 'modal']);
  const [valueInput, setValueInput] = useState('');
  const [valueNameInput, setValueNameInput] = useState('');
  const [valueCheckbox, setValueCheckbox] = useState(false);
  const [validInput, setValidInput] = useState(true);
  const [validCheck, setValidCheck] = useState(true);
  const [valueDescription, setDescriptionInput] = useState('');

  const [modalActive, setModalActive] = useState(false);
  const dispatch = useAppDispatch();

  const { sendAskForm, isLoad, isError } = useAppSelector((state) => ({
    sendAskForm: state.form.sendAsk,
    isLoad: state.form.loading,
    isError: state.form.error,
  }));

  const onSendForm = () => {
    const resultValid = isEmailValid(valueInput);
    if (resultValid) {
      if (valueCheckbox) {
        setValidInput(true);
        setModalActive(true);
        const params = {
          name: valueNameInput,
          email: valueInput,
          question: valueDescription,
        };
        dispatch(sendAskFormThunk(params));
      } else {
        setValidCheck(false);
      }
    } else {
      setValidInput(false);
    }
  };

  useEffect(() => {
    if (sendAskForm) {
      setValueInput('');
      setValueNameInput('');
      setValueCheckbox(false);
      setDescriptionInput('');
    }
  }, [sendAskForm]);

  return (
    <section className={styles.sectionForm}>
      <div className={styles.form}>
        <div className={styles.content}>
          <TitleSection
            title={
              title
                ? t('team:questionName', { name: title })
                : t('team:question')
            }
          />
          <p>
            {t('team:information')}&nbsp;
            {t('team:informationPhone')}
          </p>

          <div className={styles.formBody}>
            <Input
              placeholder={`${t('team:name')}`}
              value={valueNameInput}
              onChange={(e) => setValueNameInput(e.toString())}
            />
            <Input
              placeholder='Email'
              isValid={validInput}
              value={valueInput}
              onChange={(e) => setValueInput(e.toString())}
            />
            <Textarea
              value={valueDescription}
              placeholder={`${t('team:descriptionQuestion')}`}
              onChange={(e) => setDescriptionInput(e.target.value)}
            />
            <div
              className={
                validCheck
                  ? styles.check
                  : `${styles.check} ${styles.noValidcheck}`
              }
            >
              <Checkbox
                checked={valueCheckbox}
                onChange={(event) => setValueCheckbox(event.target.checked)}
              />
              <p className={styles.textCheck}>
                <Trans
                  t={t}
                  defaults={`${t('team:agree')}`}
                  components={{
                    bold: <MainLink path={PersonalData}> </MainLink>,
                  }}
                />
              </p>
            </div>
            <Button className={styles.button} onClick={onSendForm}>
              {t('team:buttonSend')}
            </Button>
          </div>
        </div>
      </div>
      <Modal active={modalActive} setActive={setModalActive}>
        {isLoad ? (
          <Loader />
        ) : (
          <>
            {isError ? (
              <div className={styles.textError}>
                <TitleSection title={t('modal:error')} />{' '}
              </div>
            ) : (
              <div className={styles.titleBlock}>
                <TitleSection title={t('modal:requestSend')} />
                <p className={styles.text}>{t('modal:meContact')}</p>
              </div>
            )}
          </>
        )}
      </Modal>
    </section>
  );
};
export default AskLawyer;
