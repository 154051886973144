import { useTranslation } from 'react-i18next';
import ImageContainerMain from '../../ui/shared/entities/imageContainerMain/ImageContainerMain';
import Main from '../../../assets/images/team/main.webp';
import Title from '../../ui/shared/title/Title';
import styles from './sectionImage.module.scss';

const SectionImage = () => {
  const { t } = useTranslation('team');
  return (
    <section className={styles.sectionImageTeam}>
      <ImageContainerMain alt='Team' src={Main}>
        <div className={styles.teamText}>
          <Title title={t('title')} />
          <p>{t('description')}</p>
        </div>
      </ImageContainerMain>
    </section>
  );
};
export default SectionImage;
