import { useTranslation } from 'react-i18next';
import Links from '../../../../navigation/links';
import NavigationLink from '../../../ui/shared/navigate-link/NavigateLink';
import styles from './cardVideo.module.scss';

interface Props {
  date: string;
  text: string;
  mini?: boolean;
  link: string;
}

const CardVideo = ({ date, text, mini = false, link }: Props) => {
  const classVideo = `${mini ? styles.cardVideoMini : styles.cardVideo}`;
  const classVideoText = `${mini ? styles.cardVideoMiniText : ''}`;
  const { i18n } = useTranslation();

  return (
    <div className={styles.video}>
      <div className={classVideo}>
        <div className={styles.videoFrame}>
          <iframe
            width='100%'
            height={mini ? '145px' : '500px'}
            src={link}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          />
        </div>

        {i18n.language === 'ru' && (
          <div className={classVideoText}>
            <NavigationLink path={Links.video}>
              <span>{date}</span>
              <p className={styles.text}>{text}</p>
            </NavigationLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardVideo;
