import { CSSProperties, ReactNode } from 'react';
import styles from './cardInfo.module.scss';
// card example Home section- Actual

interface Props {
  children?: ReactNode;
  color?: 'grey' | 'accent';
  title?: string;
  text?: string;
  imageSrc?: string;
  style?: CSSProperties;
  textFooter?: string;
}

const CardInfo = ({
  children,
  color,
  title = '',
  // text = '',
  imageSrc = '',
  style,
  textFooter,
}: Props) => {
  const getCardColor = () => {
    switch (color) {
      case 'accent':
        return styles.accent;
      case 'grey':
        return styles.grey;
      default:
        return '';
    }
  };

  const cardClassBackground = `${styles.cardInfo} ${getCardColor()}`;
  return (
    <div
      className={
        imageSrc
          ? `${styles.cardInfoFlex} ${cardClassBackground}`
          : cardClassBackground
      }
      style={style}
    >
      <div className={imageSrc ? styles.cardInfoFlexBody : styles.cardInfoBody}>
        <div className={styles.cardText}>
          {children && (
            <div className={`card-info ${styles.cardInfoHeader}`}>
              {children}
            </div>
          )}
          <div className={styles.cardInfoTitleWrapper}>
            <h5 className={styles.cardInfoTitle}>{title}</h5>
            {/* <div
              className={styles.cardInfoText}
              dangerouslySetInnerHTML={{
                __html: text ?? '',
              }}
            /> */}
          </div>
          {textFooter && <p className={styles.cardTextFooter}>{textFooter}</p>}
        </div>
      </div>
      {imageSrc && (
        <div className={styles.image}>
          <img src={imageSrc} alt='' />
        </div>
      )}
    </div>
  );
};

export default CardInfo;
