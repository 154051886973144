/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { BASE_URL } from 'src/axios/baseUrl';
import Description from 'src/components/company/description/Description';
import Experience from 'src/components/company/experience/Experience';
import SectionImage from 'src/components/company/sectionImage/SectionImage';
import Container from 'src/components/ui/shared/container/Container';
import SliderRecommended from 'src/components/ui/widgets/sliderRecommended/SliderRecommended';
import { getInformationCompanyThunk } from 'src/redux/aboutCompany/thunk';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import styles from './company.module.scss';
import One from 'src/assets/images/company/one.png';
import Two from 'src/assets/images/company/two.png';
import Three from 'src/assets/images/company/three.png';
import { TAboutDescription } from 'src/types/aboutCompany';
import { useTranslation } from 'react-i18next';
import Main from 'src/assets/images/company/main.png';
import { getRatingCompanyThunk } from 'src/redux/rating/thunk';
import { Helmet } from 'react-helmet-async';

const Company = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const { t } = useTranslation('company');
  const { t: c } = useTranslation('common');

  useEffect(() => {
    dispatch(getInformationCompanyThunk());
    dispatch(getRatingCompanyThunk());
  }, [i18n.language]);

  const { aboutCompanyInfo, rating } = useAppSelector((state) => ({
    aboutCompanyInfo: state.aboutCompany.aboutCompanyInfo,
    rating: state.rating.ratingCompanyInfo,
  }));

  const descriptionInformation: TAboutDescription[] = [
    {
      id: 0,
      title: aboutCompanyInfo?.about_middle_header || '',
      img: `${BASE_URL}${aboutCompanyInfo?.about_banner.url}` || One,
      desc: aboutCompanyInfo?.about_middle_text || '',
    },
    {
      id: 1,
      title: aboutCompanyInfo?.about_short_header || '',
      img: `${BASE_URL}${aboutCompanyInfo?.about_middle_banner.url}` || Two,
      desc: aboutCompanyInfo?.about_short_text || '',
    },
    {
      id: 2,
      title: aboutCompanyInfo?.about_bottom_header || '',
      img: `${BASE_URL}${aboutCompanyInfo?.about_bottom_banner.url}` || Three,
      desc: aboutCompanyInfo?.about_bottom_text || '',
    },
  ];

  return (
    <>
      <Helmet>
        <title>{c('pageTitles.company')}</title>
      </Helmet>
      <div>
        <>
          <SectionImage
            description={aboutCompanyInfo?.about_top_text || t('description')}
            title={aboutCompanyInfo?.about_top_header || t('title')}
            image={
              aboutCompanyInfo
                ? `${BASE_URL}${aboutCompanyInfo?.mainpage_banner.url}`
                : Main
            }
          />
          {aboutCompanyInfo && (
            <Container isPaddingTop={false}>
              <Description description={descriptionInformation} />
            </Container>
          )}
          <Experience members={aboutCompanyInfo?.about_numbers || []} />
          <div className={styles.company}>
            {rating.length > 0 && <SliderRecommended rating={rating} />}
          </div>
        </>
      </div>
    </>
  );
};
export default Company;
