/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getActualNewsThunk } from 'src/redux/news/thunks';
import { getRatingHomeThunk } from 'src/redux/rating/thunk';
import {
  getBannerThunk,
  getImageRatingThunk,
  getIsVideoThunk,
} from 'src/redux/staticPages/thunk';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import Actual from '../components/home/actual/Actual';
import Category from '../components/home/category/Category';
import FeedbackForm from '../components/home/feedbackForm/FeedbackForm';
import Recommended from '../components/home/recommended/Recommended';
import SectionImage from '../components/home/sectionImage/SectionImage';
import Video from '../components/home/sectionVideo/Video';
import Container from '../components/ui/shared/container/Container';
import { Helmet } from 'react-helmet-async';

const Home = () => {
  const { t, i18n } = useTranslation('common');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getRatingHomeThunk());
    dispatch(getImageRatingThunk());
    dispatch(getActualNewsThunk(i18n.language));
    dispatch(getIsVideoThunk());
    dispatch(getBannerThunk());
  }, [i18n.language]);

  const { isActiveVideo } = useAppSelector((state) => ({
    isActiveVideo: state.staticPages.isActiveVideo,
  }));

  const { actualNews } = useAppSelector((state) => ({
    actualNews: state.news.actualNews?.results,
  }));

  const shouldShowNews = Array.isArray(actualNews) && actualNews.length > 0;

  return (
    <>
      <Helmet>
        <title>{t('pageTitles.home')}</title>
      </Helmet>
      <SectionImage />
      <Container isPaddingTop={false}>
        <Category />
        {shouldShowNews && <Actual />}
        {isActiveVideo && i18n.language !== 'chi' && <Video />}
        <Recommended />
        <FeedbackForm />
      </Container>
    </>
  );
};
export default Home;
