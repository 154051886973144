import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCategories,
  getCategoryServices,
  getCurrentServiceCategory,
  getObligationInfo,
  getProjectCategory,
} from 'src/api/categoriesServices';

const getAllCategoriesThunk = createAsyncThunk('getAllCategories', async () => {
  try {
    const { data: categories } = await getCategories();
    return categories;
  } catch (e) {
    console.log(e);
  }
});

const getCategoryAllServicesThunk = createAsyncThunk(
  'getCategoryAllServices',
  async (id: string) => {
    try {
      const { data: categoryServices } = await getCategoryServices(id);
      return categoryServices;
    } catch (e) {
      console.log(e);
    }
  },
);

const getCurrentServiceThunk = createAsyncThunk(
  'getCurrentService',
  async (id: string) => {
    try {
      const { data: currentServiceCategory } = await getCurrentServiceCategory(
        id,
      );
      return currentServiceCategory;
    } catch (e) {
      console.log(e);
    }
  },
);

const getProjectCategoryThunk = createAsyncThunk(
  'getProjectCategory',
  async () => {
    try {
      const { data: projectServices } = await getProjectCategory();
      return projectServices;
    } catch (e) {
      console.log(e);
    }
  },
);

const getObligationServicesThunk = createAsyncThunk(
  'getObligationServices',
  async () => {
    try {
      const { data: obligationServices } = await getObligationInfo();
      return obligationServices;
    } catch (e) {
      console.log(e);
    }
  },
);

export {
  getAllCategoriesThunk,
  getCategoryAllServicesThunk,
  getCurrentServiceThunk,
  getProjectCategoryThunk,
  getObligationServicesThunk,
};
