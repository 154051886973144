/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Container from 'src/components/ui/shared/container/Container';
import PublicationAndBooks from 'src/components/analytics/publicationAndBooks/PublicationAndBooks';
import styles from './analytics.module.scss';
import { useTranslation } from 'react-i18next';
import routers from 'src/navigation/routers';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import {
  getAllBooksThunk,
  getAllPublicationThunk,
} from 'src/redux/publication/thunk';
import Review from 'src/components/analytics/review/Review';
import Loader from 'src/components/ui/shared/loader/Loader';
import TitleSection from 'src/components/ui/shared/titleSection/TitleSection';
import { getReviewListCategoriesThunk } from 'src/redux/review/thunk';
import { Helmet } from 'react-helmet-async';

const Analytics = () => {
  const { t, i18n } = useTranslation('common');
  const dispatch = useAppDispatch();

  const {
    publication,
    reviewListCategories,
    books,
    isLoad,
    isLoadReview,
    dataReview,
  } = useAppSelector((state) => ({
    publication: state.publication.publicationAll?.results,
    reviewListCategories: state.review.reviewListCategories,
    isLoad: state.publication.loadingAll,
    books: state.publication.booksAll?.results,
    isLoadReview: state.review.loading,
    dataReview: state.review.langDataReview,
  }));

  useEffect(() => {
    dispatch(getAllPublicationThunk({}));
    dispatch(getAllBooksThunk({}));
    dispatch(getReviewListCategoriesThunk());
  }, [dispatch, i18n.language]);

  const shouldRenderReview = dataReview !== 'noData';

  return (
    <Container className={styles.content}>
      <Helmet>
        <title>{t('pageTitles.analytics')}</title>
      </Helmet>
      {isLoad || isLoadReview ? (
        <Loader />
      ) : (
        <>
          {shouldRenderReview && (
            <>
              <TitleSection
                className={styles.titleReview}
                title={t('bankruptcyTitle')}
              />
              <Review cards={reviewListCategories} />
            </>
          )}
          {publication && publication.length > 0 && (
            <PublicationAndBooks
              title={t('publication')}
              cards={publication}
              link={`${routers.analytics}/publication`}
            />
          )}
          {books && books.length > 0 && (
            <PublicationAndBooks
              title={t('books')}
              cards={books}
              link={`${routers.analytics}/book`}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default Analytics;
