import styles from './titleSection.module.scss';

interface Props {
  title: string;
  className?: string;
}

const TitleSection = ({ title, className }: Props) => {
  const classTitle = `${styles.title} ${className}`;
  return <h2 className={classTitle}>{title}</h2>;
};

export default TitleSection;
