import { useEffect, useRef, useState, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../widgets/footer/Footer';
import Header from '../widgets/header/Header';
import styles from './layout.module.scss';

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props) => {
  const topRef = useRef(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    rootRef.current &&
      rootRef.current.scrollTo({
        top: 0,
      });
  }, [pathname]);

  useEffect(() => {
    if (topRef.current) {
      const currentRef = topRef.current;
      const scrollObserver = new IntersectionObserver((entries) => {
        if (!entries[0].isIntersecting) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      });

      scrollObserver.observe(currentRef);
      return () => scrollObserver.unobserve(currentRef);
    }
  }, []);

  return (
    <div ref={rootRef} className={styles.layout}>
      <div ref={topRef} id='sticky' />
      <Header sticky={isSticky} />
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
