import { Get } from 'src/axios';
import { TPublication, TPublicationItem } from 'src/types/publication';

const getAllPublication = (page = 1, type = 'publication') =>
  Get<TPublication>(`/news/${type}/`, {
    params: { is_book: 'False', page },
  });

const getCurrentPublication = (id: string) =>
  Get<TPublicationItem>(`/news/publication/${id}/`);

const getAllBooks = (page = 1, type = 'publication') =>
  Get<TPublication>(`/news/${type}/`, {
    params: { is_book: 'True', page },
  });

export { getAllPublication, getCurrentPublication, getAllBooks };
