/* eslint-disable react-hooks/exhaustive-deps */
import BasicServices from 'src/components/services/basicServices/BasicServices';
import DescriptionService from 'src/components/services/shared/descriptionService/DescriptionService';
import Container from 'src/components/ui/shared/container/Container';
import KeyContacts from 'src/components/ui/widgets/keyContacts/KeyContacts';
import BenefitsCustomers from 'src/components/services/benefitsCustomers/BenefitsCustomers';
import ContainerFlex from 'src/components/ui/shared/containerFlex/ContainerFlex';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { useParams } from 'react-router-dom';
import { getCurrentServiceThunk } from 'src/redux/categoriesServices/thunk';
import routers from 'src/navigation/routers';
import { setBreadCrumbs } from 'src/redux/categoriesServices';
import { Helmet } from 'react-helmet-async';

const CurrentServicePage = () => {
  const dispatch = useAppDispatch();

  const params = useParams();

  const { t, i18n } = useTranslation('common');

  const currentServiceCategory = useAppSelector(
    (state) => state.categoriesServices.currentServiceCategory,
  );

  useEffect(() => {
    if (params?.currentIdService) {
      dispatch(getCurrentServiceThunk(params.currentIdService));
    }
  }, [dispatch, i18n.language, params.idService]);

  useEffect(() => {
    if (currentServiceCategory) {
      const links = [
        {
          title: t('navigationLinks.services'),
          path: routers.services,
        },
        {
          title: currentServiceCategory?.category.name,
          path: `${routers.services}/${currentServiceCategory?.category.id}`,
        },
        {
          title: currentServiceCategory?.name,
          path: `${routers.services}/${currentServiceCategory?.category.id}/${currentServiceCategory.id}`,
        },
      ];

      dispatch(setBreadCrumbs(links));
    }
  }, [currentServiceCategory]);

  return (
    <div>
      <Helmet>
        <title>{t('pageTitles.services')}</title>
      </Helmet>
      {currentServiceCategory && (
        <DescriptionService
          text={currentServiceCategory.description}
          title={currentServiceCategory.name}
        />
      )}
      <Container isPaddingTop={false}>
        <ContainerFlex
          panelLeft={
            <>
              {currentServiceCategory && (
                <>
                  <BasicServices
                    title={currentServiceCategory.services_list_header}
                    serviceList={currentServiceCategory?.main_services_list}
                  />
                  {currentServiceCategory?.features_list[0]?.title !== '' && (
                    <BenefitsCustomers
                      featuresList={currentServiceCategory?.features_list}
                    />
                  )}
                </>
              )}
            </>
          }
          panelRight={
            <KeyContacts contacts={currentServiceCategory?.key_contacts} />
          }
        />
      </Container>
    </div>
  );
};
export default CurrentServicePage;
