import { Get, Post } from 'src/axios';
import { TImagesRating } from 'src/types/rating';
import { TBannerHome } from 'src/types/staticpages';

type TVideo = {
  is_active: boolean;
};

export const getImageOrganizationHome = () =>
  Get<TImagesRating[]>('/staticpages/featuredCustomers/');

export const getVideo = () => Get<TVideo>('/staticpages/video/');

export const getBannerHome = () => Get<TBannerHome[]>('/staticpages/banner/');

export const setLanguage = (language: string) =>
  Post(`/language`, {
    language,
  });
