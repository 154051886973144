import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import isEmailValid from '../../../utils/isValidEmail';
import Button from '../../ui/shared/button/Button';
import Input from '../../ui/shared/input/Input';
import Modal from '../../ui/shared/modal/Modal';
import TitleSection from '../../ui/shared/titleSection/TitleSection';
import styles from './feedbackForm.module.scss';

const FeedbackForm = () => {
  const [modalActive, setModalActive] = useState(false);
  const { t } = useTranslation(['home', 'modal']);
  const [valueInput, setValueInput] = useState<string>('');
  const [validInput, setValidInput] = useState(true);

  const onOpenModal = () => {
    if (validInput) {
      setModalActive(true);
    }
  };

  useEffect(() => {
    const resultValid = valueInput && isEmailValid(valueInput);
    if (resultValid) {
      setValidInput(true);
    } else {
      setValidInput(false);
    }
  }, [valueInput]);

  const onConfirm = () => {
    setValueInput('');
    setModalActive(false);
  };

  return (
    <section className={styles.feedback}>
      <div className={styles.feedbackBody}>
        <TitleSection title={t('home:goNewsletter')} />
        <p>{t('home:feedbackText')}</p>
        <div className={styles.feedbackForm}>
          <form
            method='POST'
            action='https://cp.unisender.com/ru/subscribe?hash=6gdoms3br8qo1ebwnnfu1716are4o9t7w8zu34k5qwyxfym5bbfjo'
            name='subscribtion_form'
            target='_blank'
            className={styles.feedbackForm}
          >
            <Input
              name='email'
              placeholder='Email'
              isValid={valueInput === '' ? true : validInput}
              value={valueInput}
              onChange={(value) => setValueInput(value.toString())}
            />
            <input type='hidden' name='charset' value='UTF-8' />
            <input type='hidden' name='default_list_id' value='1' />
            <input type='hidden' name='overwrite' value='2' />
            <input type='hidden' name='is_v5' value='1' />
            <Button
              type='submit'
              disabled={valueInput === '' ? false : !validInput}
              onClick={onOpenModal}
            >
              {t('subscribe')}
            </Button>
          </form>
        </div>
      </div>
      <Modal active={modalActive} setActive={setModalActive} isCross={false}>
        <div className={styles.modalFeedback}>
          <TitleSection title={`${t('modal:subscription')}`} />
          <p className={styles.text}>
            <Trans
              t={t}
              defaults={`${t('modal:infoSubscription')}`}
              values={{ email: valueInput }}
              components={{ bold: <strong /> }}
            />
          </p>
          <Button onClick={onConfirm}>{t('confirm')}</Button>
        </div>
      </Modal>
    </section>
  );
};

export default FeedbackForm;
