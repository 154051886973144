const formatDateForApi = (date: any) => {
  const lastDayDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const lastDay = lastDayDate.getDate();

  let month = date.getMonth() + 1;
  if (month < 10) month = '0' + month;
  const year = date.getFullYear();
  return {
    start_date: year + '-' + month + '-' + '01',
    end_date: year + '-' + month + '-' + lastDay,
  };
};

export default formatDateForApi;
