import { ChangeEvent } from 'react';
import styles from './textarea.module.scss';

interface Props {
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea = ({
  placeholder = '',
  value,
  disabled = false,
  onChange,
}: Props) => (
  <textarea
    className={styles.textarea}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    disabled={disabled}
  />
);

export default Textarea;
