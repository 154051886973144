import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useFormattedDate } from 'src/hooks';
import routers from 'src/navigation/routers';
import { useAppSelector } from 'src/redux/store';
import { TEventItem } from 'src/types/news';
import { ReactComponent as ArrowGoIcon } from '../../../../assets/images/icons/arrowGo.svg';
import Button from '../../shared/button/Button';
import CardInfo from '../../shared/cardInfo/CardInfo';
import Loader from '../../shared/loader/Loader';
import styles from './newsBlock.module.scss';

interface IProps {
  className?: string;
  news?: TEventItem[];
}

const NewsBlock = ({ className, news }: IProps) => {
  const { t } = useTranslation('common');
  const { format } = useFormattedDate();
  const navigate = useNavigate();

  const loadingNews = useAppSelector((state) => state.news.loadingNews);
  const slicedNews = news?.slice(0, 4);

  return (
    <section className={`${styles.root} ${className}`}>
      <div className={styles.headerWrapper}>
        <h2 className={styles.title}>{t('newsEvent')}</h2>
        <Link to={routers.pressCenter}>
          <ArrowGoIcon />
        </Link>
      </div>
      <ul className={styles.newsList}>
        {loadingNews ? (
          <div className={styles.newsListLoader}>
            <Loader />
          </div>
        ) : (
          <>
            {slicedNews?.map((item, index) => {
              const isEven = index % 2;

              return (
                <li
                  className={styles.newsItem}
                  key={index}
                  onClick={() =>
                    navigate(`${routers.pressCenter}/news/${item.id}`)
                  }
                >
                  <CardInfo
                    title={item?.name}
                    text={item?.additional_text}
                    color={isEven ? 'accent' : 'grey'}
                    // TODO needs to fix image src problem
                    // imageSrc={item.file.file}
                  >
                    <span className={styles.date}>
                      {format(item?.publication_date)}
                    </span>
                  </CardInfo>
                </li>
              );
            })}
          </>
        )}
      </ul>
      <Link to={routers.pressCenter}>
        <Button icon={true} className={styles.buttonSeeAll}>
          {t('seeAll')}
        </Button>
      </Link>
    </section>
  );
};

export default NewsBlock;
