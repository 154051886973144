export default {
  home: '/',
  team: '/team',
  company: '/company',
  services: '/services',
  pressCenter: '/press-center',
  analytics: '/analytics',
  contact: '/contact',
  informationBonds: '/information-bonds',
  events: '/events',
  comments: '/comments',
  news: '/news',
  assetsUnlocking: '/assets-unlocking',
  lawsuit: '/lawsuit-against-belarus',
  career: '/career',
  // 301 redirect
  groupClaimOld:
    ':lang/:shortLang/press-centr/novosti-i-sobytiya/gruppovoj-isk-protiv-euroclear-i-clearstream-okno-vozmozhnostej-/',
  groupClaimOldSecondary:
    ':lang/:shortLang/press-centr/novosti-i-sobytiya/gruppovoj-isk-protiv-evroklir-iniciirovana-dosudebnaya-stadiya/',
  groupClaimNew: '/press-center/news/510',
};
