import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  children: ReactNode;
  path: string;
  preventScrollReset?: boolean;
  target?: string;
}

const MainLink = ({ path, children, target = '_blank' }: Props) => (
  <Link to={path} target={target}>
    {children}
  </Link>
);

export default MainLink;
