/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import Card from '../../ui/shared/card/Card';
import CardImageTextFooter from '../../ui/shared/cardImageTextFooter/CardImageTextFooter';
import MainLink from '../../ui/shared/link/MainLink';
import Tabs from '../../ui/shared/tabs/Tabs';
import Accordion from '../../ui/shared/accordion/Accordion';
import styles from './category.module.scss';
import { useAppSelector } from 'src/redux/store';
import routers from 'src/navigation/routers';
import { useTranslation } from 'react-i18next';
import Chambers from '../../../assets/images/recommended/chambers.png';
import Iflr from '../../../assets/images/recommended/iflr.png';

const Category = () => {
  const { categories } = useAppSelector((state) => ({
    categories: state.categoriesServices.categories,
  }));

  const { t } = useTranslation('common');

  const QUOTES = [
    { img: Chambers, text: t('quotes.one'), year: '', title: '' },

    { img: Iflr, text: t('quotes.two'), title: '', year: '2015' },
    { img: Chambers, text: t('quotes.three'), title: '', year: '2016' },
    { img: Iflr, text: t('quotes.four'), title: '', year: '2015' },
    { img: Iflr, text: t('quotes.five'), title: '', year: '2015' },
    { img: Chambers, text: t('quotes.six'), title: '', year: '' },
    {
      img: '',
      text: t('quotes.seven'),
      title: 'The Best Lawyers™',
      year: '2021',
    },
    { img: Chambers, text: t('quotes.eight'), title: '', year: '' },
  ];

  const [tab, setTab] = useState(0);
  const changeTab = (itemTab: number) => setTab(itemTab);

  const tabModIndex = tab % QUOTES.length;
  const tabInfo = QUOTES[tabModIndex];

  return (
    <section className={styles.category}>
      <div className={styles.categoryPc}>
        <Tabs
          options={categories}
          selectedItem={tab ?? ''}
          onChange={changeTab}
        />
        <div className={styles.bodyBlockTab}>
          <div className={styles.cardsBlock}>
            {categories[tab]?.service_set?.map((item) => (
              <div className={styles.cardsItem} key={item.id}>
                <MainLink
                  target='_self'
                  path={`${routers.services}/${categories[tab].id}/${item.id}`}
                >
                  <Card text={item.name} />
                </MainLink>
              </div>
            ))}
          </div>

          <div className={styles.cardsQuote}>
            <CardImageTextFooter
              image={tabInfo?.img}
              textFooter={tabInfo?.year}
            >
              <div>
                <h4 className={styles.projectTitle}>{tabInfo?.title}</h4>
                <p>{tabInfo?.text}</p>
              </div>
            </CardImageTextFooter>
          </div>
        </div>
      </div>
      <div className={styles.categoryMobile}>
        {categories?.map((item) => (
          <Accordion key={item.name} title={item.name} height={false} border>
            <div>
              <div className={styles.categoryItemList}>
                {item?.service_set?.map((itemCard) => (
                  <MainLink
                    target='_parent'
                    path={`${routers.services}/${item.id}/${itemCard.id}`}
                    key={itemCard.id}
                  >
                    <div>{itemCard.name}</div>
                  </MainLink>
                ))}
              </div>

              <div className={styles.cardsQuote}>
                <CardImageTextFooter
                  image={tabInfo?.img}
                  textFooter={tabInfo?.year}
                >
                  <div>
                    <h4 className={styles.projectTitle}>{tabInfo?.title}</h4>
                    <p>{tabInfo?.text}</p>
                  </div>
                </CardImageTextFooter>
              </div>
            </div>
          </Accordion>
        ))}
      </div>
    </section>
  );
};
export default Category;
