import AssetsUnlocking from 'src/components/assetsUnlocking/AssetsUnlocking';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import React from 'react';

const AssetsUnlockingPage = () => {
  const { t } = useTranslation('common');

  const description = t('meta.description.assetsUnlocking');

  return (
    <>
      <Helmet>
        <title>{t('pageTitles.assetsUnlocking')}</title>
        {description && <meta name='description' content={description} />}
      </Helmet>
      <AssetsUnlocking />
    </>
  );
};
export default AssetsUnlockingPage;
