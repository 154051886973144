import { useTranslation } from 'react-i18next';
import routers from '../../../../../navigation/routers';
import NavigationLink from '../../navigate-link/NavigateLink';

const LinkMenu = () => {
  const { t } = useTranslation(['common']);
  const LINKSMENU = [
    {
      title: t('navigationLinks.team'),
      path: routers.team,
    },
    {
      title: t('navigationLinks.aboutUs'),
      path: routers.company,
    },
    {
      title: t('navigationLinks.services'),
      path: routers.services,
    },
    {
      title: t('navigationLinks.pressCenter'),
      path: routers.pressCenter,
    },
    {
      title: t('navigationLinks.analytics'),
      path: routers.analytics,
    },
    {
      title: t('navigationLinks.contacts'),
      path: routers.contact,
    },
  ];

  return (
    <>
      {LINKSMENU?.map((item) => (
        <div key={item.path}>
          <NavigationLink path={item.path} target='_parent'>
            <span>{item.title}</span>
          </NavigationLink>
        </div>
      ))}
    </>
  );
};

export default LinkMenu;
