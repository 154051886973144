/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import routers from 'src/navigation/routers';
import CardAdd from './cardAdd/CardAdd';
import CardEmployee from './cardEmployee/CardEmployee';
import MainLink from 'src/components/ui/shared/link/MainLink';
import styles from './sectionEmployes.module.scss';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { getAllEmployesThunk } from 'src/redux/team/thunk';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/ui/shared/loader/Loader';
import { BASE_URL } from 'src/axios/baseUrl';

const SectionEmployes = () => {
  const [classAdd, setClassAdd] = useState(`${styles.employesAddTeam}`);
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const { cardsEmployes, isLoading } = useAppSelector((state) => ({
    cardsEmployes: state.team.teamEmployes,
    isLoading: state.team.loading,
  }));
  const isEven = cardsEmployes?.length % 2;

  useEffect(() => {
    dispatch(getAllEmployesThunk());
  }, [i18n.language]);

  useEffect(() => {
    if (!isEven) {
      setClassAdd(`${styles.employesAddTeam} ${styles.employesAddTeamEven}`);
    } else {
      setClassAdd(`${styles.employesAddTeam}`);
    }
  }, [cardsEmployes, isEven]);

  return (
    <section className={styles.employes}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.employesCards}>
          {cardsEmployes?.map((employee) => (
            <MainLink
              preventScrollReset={false}
              path={`${routers.team}/${employee.id}`}
              key={employee.id}
              target='_self'
            >
              <CardEmployee
                src={`${BASE_URL}${employee?.photo?.url}` || ''}
                name={employee.name}
                profession={employee.position}
              />
            </MainLink>
          ))}
          <div className={classAdd}>
            <CardAdd />
          </div>
        </div>
      )}
    </section>
  );
};

export default SectionEmployes;
