import styles from './card.module.scss';

interface Props {
  text: string;
}

const Card = ({ text }: Props) => (
  <div className={styles.card}>
    <p>{text}</p>
  </div>
);

export default Card;
