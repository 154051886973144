import { TPublicationItem } from './publication';
import { TReview } from './review';
import { TFile, TMetadata } from './shared';

export type TNews = {
  id: number;
  name: string;
};

export type TEventItem = {
  id: number;
  name: string;
  publication_date: string;
  media_text: string;
  file: TFile;
  additional_text: string;
  is_event: boolean;
  link: string;
  link_name: string;
  related_events: [];
};

export type TEvent = {
  results: TEventItem[];
  count: number;
  next: string | null;
  previous: number | null;
};

export type TTeamMember = {
  id: number;
  name: string;
  position: string;
  email: string;
  description: string;
  photo: number;
};

export type TCommentItem = {
  id: number;
  name: string;
  team_member: TTeamMember;
  media: {
    id: number;
    name: string;
  };
  publication_date: string;
  media_text: string;
  media_url: string;
  media_comment: string;
  link: string;
  link_name: string;
  related_events: [];
  file?: TFile | null;
};

export type TComment = {
  results: TCommentItem[];
  count: number;
  next: string | null;
  previous: number | null;
};

export type TDataRequest = {
  is_event: 0 | 1;
  page: number;
  start_date?: string;
  end_date?: string;
  typeActives?: string;
};

export type TDataRequestComment = {
  page: number;
  start_date?: string;
  end_date?: string;
  typeActives?: string;
};

export type TActualNewsItem = {
  event: TEventItem | null;
  file: TFile | null;
  id: number;
  media_comment: TCommentItem | null;
  publication: TPublicationItem | null;
  review?: TReview;
};

export type TActualNews = TMetadata & {
  results: TActualNewsItem[];
};

export type TAssetNews = {
  Book: TPublicationItem[];
  Event: TEventItem[];
  MediaComment: TCommentItem[];
  News: TEventItem[];
  Publication: TPublicationItem[];
  Review: TReview[];
};

export type TnewsLawsuit = {
  Book: TPublicationItem[];
  Event: TEventItem[];
  MediaComment: TCommentItem[];
  News: TEventItem[];
  Publication: TPublicationItem[];
  Review: TReview[];
};

export enum KeysAssetNewsEnum {
  Book = 'Book',
  Event = 'Event',
  MediaComment = 'MediaComment',
  News = 'News',
  Publication = 'Publication',
  Review = 'Review',
}
