import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/ui/shared/button/Button';
import CardInfo from 'src/components/ui/shared/cardInfo/CardInfo';
import LinkWithIcon from 'src/components/ui/shared/linkWithIcon/LinkWithIcon';
import TitleSection from 'src/components/ui/shared/titleSection/TitleSection';
import { useFormattedDate } from 'src/hooks';
import routers from 'src/navigation/routers';
import { TEventItem } from 'src/types/news';
import styles from './news.module.scss';

const brightItems = [0, 2, 4, 6, 8];

interface IProps {
  news: TEventItem[];
  textCardFooter?: string;
  linkSeeAll: string;
}

const News = ({ news, textCardFooter, linkSeeAll }: IProps) => {
  const { t } = useTranslation('common');
  const { format } = useFormattedDate();
  const navigate = useNavigate();
  const slicedNews = news && news.slice(0, 6);

  return (
    <section className={styles.root}>
      {slicedNews && slicedNews.length > 0 && (
        <div className={styles.headingWrapper}>
          <TitleSection title={`${t('news')}`} />
        </div>
      )}
      <ul className={styles.contentList}>
        {slicedNews?.map((item, index) => {
          const isBright = brightItems.includes(index);
          return (
            <li
              className={styles.contentItem}
              onClick={() => navigate(`${routers.pressCenter}/news/${item.id}`)}
              key={index}
            >
              <CardInfo
                textFooter={textCardFooter}
                key={index}
                color={isBright ? 'accent' : 'grey'}
                title={item?.name}
                text={item?.additional_text}
              >
                <span className={styles.date}>
                  {format(item?.publication_date)}
                </span>
              </CardInfo>
            </li>
          );
        })}
      </ul>
      {slicedNews && slicedNews.length > 0 && (
        <>
          <div className={styles.showAllLink}>
            <LinkWithIcon
              path={linkSeeAll}
              text={t('seeAll')}
              target='_parent'
            />
          </div>
          <div
            className={styles.showAllButton}
            onClick={() => navigate(linkSeeAll)}
          >
            <Button icon>{t('seeAll')}</Button>
          </div>
        </>
      )}
    </section>
  );
};

export default News;
