import { useTranslation } from 'react-i18next';
import TitleSection from 'src/components/ui/shared/titleSection/TitleSection';
import styles from './whereBounds.module.scss';

const WhereBounds = () => {
  const { t } = useTranslation('bounds');
  const DATA = [
    {
      title: 'Открытая подписка',
    },
    {
      title: 'Закрытая подписка ( > 500 неквалифицированных инвесторов)',
    },
    { title: 'Допуск к организованным торгам **' },
  ];

  return (
    <section>
      <TitleSection title={t('whereBounds')} />
      <div className={styles.body}>
        <div>
          <p className={styles.title}>{t('subtitle1')}</p>
          {DATA?.map((item, index) => (
            <div key={index} className={styles.text}>
              {item.title}
            </div>
          ))}
        </div>
        <div>
          <p className={styles.title}>{t('subtitle2')}</p>
        </div>
      </div>
      <div className={styles.postText}>
        <p>
          * за исключением выпусков, обеспеченных государственной или
          муниципальной гарантией
        </p>
        <p>
          ** за исключением облигаций, предназначенных для квалифицированных
          инвесторов
        </p>
      </div>
    </section>
  );
};
export default WhereBounds;
