import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getRatingOrganizationCompany,
  getRatingOrganizationHome,
} from 'src/api/rating';

export const getRatingHomeThunk = createAsyncThunk(
  'getRatingHome',
  async () => {
    try {
      const { data: ratingHomeInfo } = await getRatingOrganizationHome();
      return ratingHomeInfo;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getRatingCompanyThunk = createAsyncThunk(
  'getRatingCompany',
  async () => {
    try {
      const { data: ratingCompanyInfo } = await getRatingOrganizationCompany();
      return ratingCompanyInfo;
    } catch (e) {
      console.log(e);
    }
  },
);
