import { createSlice } from '@reduxjs/toolkit';
import { TRating } from 'src/types/rating';
import { getRatingCompanyThunk, getRatingHomeThunk } from './thunk';

const initialState: {
  ratingHomeInfo: TRating[];
  ratingCompanyInfo: TRating[];
} = {
  ratingHomeInfo: [],
  ratingCompanyInfo: [],
};

const ratingSlice = createSlice({
  name: 'rating',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getRatingHomeThunk.fulfilled, (state, action) => {
      state.ratingHomeInfo = action.payload || [];
    });

    builder.addCase(getRatingCompanyThunk.fulfilled, (state, action) => {
      state.ratingCompanyInfo = action.payload || [];
    });
  },
});

export default ratingSlice.reducer;
