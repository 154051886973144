import { Get } from 'src/axios';
import {
  ICategory,
  ICategoryServices,
  ICurrentServiceCategory,
  IProjectService,
} from 'src/types/categoriesServices';
import { IDocument } from 'src/types/shared';

const getCategories = () => Get<ICategory[]>('/services/service_category/');
const getCategoryServices = (id: string) =>
  Get<ICategoryServices>(`/services/service_category/${id}/`);
const getCurrentServiceCategory = (id: string) =>
  Get<ICurrentServiceCategory>(`/services/service/${id}/`);
const getProjectCategory = () => Get<IProjectService[]>('/services/projects/');
const getObligationInfo = () =>
  Get<IDocument[]>('/services/obligation_holder_info/');

export {
  getCategories,
  getCategoryServices,
  getCurrentServiceCategory,
  getProjectCategory,
  getObligationInfo,
};
