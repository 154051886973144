import { useTranslation } from 'react-i18next';
import TitleSection from 'src/components/ui/shared/titleSection/TitleSection';
import styles from './advantagesBounds.module.scss';
import { ReactComponent as Arrow } from 'src/assets/images/icons/arrowGo.svg';

interface IListAdvantages {
  title: string;
}

const AdvantagesBounds = () => {
  const { t } = useTranslation('bounds');

  const DATA = [
    { title: 'возможность размещать выпускиоблигаций с обеспечением;' },
    {
      title:
        'возможность внесения технических изменений в эмиссионную документациюбез участия владельцевоблигаций;',
    },
    {
      title:
        'упрощение коллективного взаимодействия владельцев облигаций с эмитентом и/или лицом, предоставившим обеспечение, в том числе в рамках судебных процессов.',
    },
  ];
  const DATAI = [
    { title: 'упрощение контроля за рисками;' },
    {
      title:
        'упрощение участия в судебных спорах, а также исключение дифференцированного подхода в отношении разных владельцев облигаций;',
    },
    {
      title:
        'упрощение взаимодействия с лицом, предоставившим обеспечение по облигациям.',
    },
  ];

  const renderAdvantages = (title: string, list: IListAdvantages[]) => {
    return (
      <div className={styles.advantagesBody}>
        <h3 className={styles.title}>{title}</h3>
        {list?.map((item, index) => (
          <div key={index} className={styles.text}>
            <Arrow /> {item.title}
          </div>
        ))}
      </div>
    );
  };

  return (
    <section className={styles.advantages}>
      <TitleSection title={t('advantagesBounds')} />
      {renderAdvantages(`${t('category1')}`, DATA)}
      {renderAdvantages(`${t('category2')}`, DATAI)}
    </section>
  );
};
export default AdvantagesBounds;
