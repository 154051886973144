import { useTranslation } from 'react-i18next';
import Accordion from 'src/components/ui/shared/accordion/Accordion';
import TitleSection from 'src/components/ui/shared/titleSection/TitleSection';
import { TFeatureList } from 'src/types/categoriesServices';
import styles from './benefitsCustomers.module.scss';

interface IProps {
  featuresList: TFeatureList[];
}

const BenefitsCustomers = ({ featuresList }: IProps) => {
  const { t } = useTranslation('common');
  return (
    <section className={styles.benefitsSection}>
      <TitleSection title={t('benefitsCustomers')} />
      <div className={styles.benefitsBody}>
        {featuresList?.map((item) => (
          <Accordion title={item.title} height={false} border key={item.title}>
            <p>{item.text}</p>
          </Accordion>
        ))}
      </div>
    </section>
  );
};
export default BenefitsCustomers;
