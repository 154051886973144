import { useEffect, useState } from 'react';
import LinkMenu from '../../../shared/entities/linkMenu/LinkMenu';
import LogoSticky from '../../../../../assets/images/logo/logoImages.svg';
import Logo from '../../../../../assets/images/logo/logoWhite.svg';
import MenuMobile from '../../../../../assets/images/icons/iconMenu.svg';
import MenuMobileSticky from '../../../../../assets/images/icons/iconMenuBlack.svg';
import MobileMenu from '../../mobileMenu/MobileMenu';
import routers from '../../../../../navigation/routers';
import MainLink from '../../../shared/link/MainLink';
import styles from './internalHeader.module.scss';
import { useLocation } from 'react-router-dom';

interface Props {
  sticky?: boolean;
}
const PATH = [routers.home, routers.team, routers.company];

const InternalHeader = ({ sticky = true }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [classColorFont, setClassColorFont] = useState('');
  const classHeader = `${styles.internalHeader} ${classColorFont} ${
    sticky ? styles.internalStickyHeader : ''
  }`;
  const router = useLocation();

  useEffect(() => {
    const isMatches = PATH.filter((path) => path === router.pathname);
    if (isMatches.length) {
      setClassColorFont(`${styles.whiteFont}`);
    } else {
      setClassColorFont('');
    }
  }, [router.pathname]);

  return (
    <>
      <div className={classHeader}>
        <MainLink path={routers.home} target='_parent'>
          <div className={styles.internalHeaderLogo}>
            {!classColorFont.length ? (
              <img src={LogoSticky} alt='menu' />
            ) : (
              <img
                height={32}
                src={sticky ? LogoSticky : Logo}
                alt='nektorov, saveliev'
              />
            )}
          </div>
        </MainLink>
        <button
          type='button'
          className={styles.internalHeaderMenu}
          onClick={() => setIsVisible(true)}
        >
          {!classColorFont.length ? (
            <img src={MenuMobileSticky} alt='menu' />
          ) : (
            <img src={sticky ? MenuMobileSticky : MenuMobile} alt='menu' />
          )}
        </button>
        <div className={styles.internalHeaderLink}>
          <LinkMenu />
        </div>
      </div>
      <MobileMenu onClose={setIsVisible} isOpen={isVisible} />
    </>
  );
};

export default InternalHeader;
