import { useTranslation } from 'react-i18next';
import styles from './filesDownload.module.scss';
import File from 'src/assets/images/icons/pdf.svg';
import { IDocument } from 'src/types/shared';
import { Link } from 'react-router-dom';
import { BASE_URL } from 'src/axios/baseUrl';

interface IProps {
  className?: string;
  files: IDocument[];
}

const FilesDownload = ({ className, files }: IProps) => {
  const { t } = useTranslation('common');

  return (
    <section className={className}>
      <h2 className={styles.title}>{t('filesDownload')}</h2>
      <div className={styles.body}>
        {files?.map((item) => (
          <Link
            key={item.document.id}
            to={`${BASE_URL}${item?.document?.url}` || ''}
            target='_blank'
          >
            <div className={styles.fileItem}>
              <img src={File} alt='download' />
              <p>{item?.document?.original_filename}</p>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};
export default FilesDownload;
