import { ReactNode } from 'react';
import styles from './containerFlex.module.scss';

interface Props {
  panelLeft: ReactNode;
  panelRight: ReactNode;
}

const ContainerFlex = ({ panelLeft, panelRight }: Props) => (
  <div className={styles.containerFlex}>
    <div className={styles.panelLeft}>{panelLeft}</div>
    <div className={styles.panelRight}>{panelRight}</div>
  </div>
);
export default ContainerFlex;
