import { ReactNode } from 'react';
import styles from './container.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
  isPaddingTop?: boolean;
}

const Container = ({ children, className, isPaddingTop = true }: Props) => {
  const classContainer = `${styles.container} ${
    isPaddingTop ? '' : styles.containerNoPadding
  }`;

  return <div className={`${classContainer} ${className}`}>{children}</div>;
};

export default Container;
