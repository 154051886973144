import { NavLink, useLocation } from 'react-router-dom';
import { useAppSelector } from 'src/redux/store';
import styles from './breadCrumbs.module.scss';

interface IProps {
  className?: string;
  type: string;
}

const BreadCrumbs = ({ className, type }: IProps) => {
  const location = useLocation();

  const currentItems = useAppSelector((state) => {
    switch (type) {
      case 'services':
        return state.categoriesServices.breadCrumbs;
      default:
        return [];
    }
  });

  return (
    <div className={`${styles.root} ${className}`}>
      {currentItems.map((crumbs, index) => {
        return (
          <NavLink
            key={index}
            to={crumbs.path}
            className={
              location.pathname === crumbs.path
                ? `${styles.link} ${styles.activeLink}`
                : styles.link
            }
          >
            {crumbs.title}&nbsp;<span>/</span>&nbsp;
          </NavLink>
        );
      })}
    </div>
  );
};
export default BreadCrumbs;
