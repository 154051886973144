import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactComponent from 'src/components/contact/ContactComponent';
import AskLawyer from 'src/components/team/askLawyer/AskLawyer';
import Container from 'src/components/ui/shared/container/Container';
import ContainerFlex from 'src/components/ui/shared/containerFlex/ContainerFlex';
import Tabs from 'src/components/ui/shared/tabs/Tabs';
import Title from 'src/components/ui/shared/title/Title';
import Map from 'src/components/ui/widgets/map/Map';
import { useAppSelector } from 'src/redux/store';
import { TContact } from 'src/types/contacts';
import styles from './contact.module.scss';
import { Helmet } from 'react-helmet-async';

type ItemOptions = { id: number | string; name: string };

const OFFICES_MAP: Record<string, JSX.Element> = {
  Ru: (
    <Map src='https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0,%20%D0%A1%D0%BC%D0%BE%D0%BB%D0%B5%D0%BD%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BF%D0%BB%D0%BE%D1%89%D0%B0%D0%B4%D1%8C,%20%D0%B4%D0%BE%D0%BC%203+(NSPLAW)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed' />
  ),
  Ae: (
    <Map src='https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Ras%20Al%20Khaimah%20Economic%20Zone%20-%20RAKEZ+(NSPLAW)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed' />
  ),
  Il: (
    <Map src='https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Zeev%20Jabotinsky%20St%207,%20Ramat%20Gan,%20Israel+(NSPLAW)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed' />
  ),
};

const Contact = () => {
  const { t } = useTranslation('common');
  const [addressOptions, setAddressOptions] = useState<ItemOptions[]>([]);
  const [addressInformation, setAddressInformation] = useState<TContact>();

  const { contacts } = useAppSelector((state) => ({
    contacts: state.contacts.contacts,
  }));

  useEffect(() => {
    if (contacts.length > 0) {
      const options = contacts.map((item) => {
        return { id: item.title, name: item.title };
      });

      setAddressOptions(options);
    }
  }, [contacts]);

  const [tab, setTab] = useState(0);
  const changeTab = (itemTab: number) => setTab(itemTab);

  const MapComponent = useMemo(
    () => OFFICES_MAP[addressInformation?.country_code ?? 'Ru'],
    [addressInformation?.country_code],
  );

  useEffect(() => {
    setAddressInformation(contacts[tab]);
  }, [contacts, tab]);

  return (
    <>
      <Helmet>
        <title>{t('pageTitles.contact')}</title>
      </Helmet>
      <Container isPaddingTop={false} className={styles.contact}>
        <Title title={`${t('contacts')}`} />
        <div className={styles.contactAddress}>
          <Tabs
            options={addressOptions}
            selectedItem={tab ?? ''}
            onChange={changeTab}
          />
        </div>
        <ContainerFlex
          panelLeft={
            <ContactComponent addressInformation={addressInformation} />
          }
          panelRight={<AskLawyer />}
        />
      </Container>
      {MapComponent}
    </>
  );
};
export default Contact;
