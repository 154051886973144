import CardInfo from 'src/components/ui/shared/cardInfo/CardInfo';
import LinkWithIcon from 'src/components/ui/shared/linkWithIcon/LinkWithIcon';
import TitleSection from 'src/components/ui/shared/titleSection/TitleSection';
import styles from './comments.module.scss';
import Button from 'src/components/ui/shared/button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routers from 'src/navigation/routers';
import { TCommentItem } from 'src/types/news';

interface IProps {
  comments: TCommentItem[];
  textCardFooter?: string;
  linkSeeAll: string;
}

const Comments = ({ comments, textCardFooter, linkSeeAll }: IProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const slicedComments = comments && comments.slice(0, 6);

  return (
    <section className={styles.root}>
      {slicedComments && slicedComments.length > 0 && (
        <div className={styles.headingWrapper}>
          <TitleSection title={`${t('mediaComments')}`} />
        </div>
      )}
      <ul className={styles.contentList}>
        {slicedComments?.map((comment, index) => {
          return (
            <li
              className={styles.contentItem}
              onClick={() =>
                navigate(`${routers.pressCenter}/comments/${comment.id}`)
              }
              key={index}
            >
              <CardInfo
                key={index}
                title={comment?.name}
                textFooter={textCardFooter}
                text={`${comment?.team_member?.position} ${comment?.team_member?.name}`}
              >
                <span>{comment.media.name}</span>
              </CardInfo>
            </li>
          );
        })}
      </ul>

      {slicedComments && slicedComments.length > 0 && (
        <>
          <div className={styles.showAllLink}>
            <LinkWithIcon
              path={linkSeeAll}
              text={t('seeAll')}
              target='_parent'
            />
          </div>
          <div
            className={styles.showAllButton}
            onClick={() => navigate(linkSeeAll)}
          >
            <Button icon>{t('seeAll')}</Button>
          </div>
        </>
      )}
    </section>
  );
};

export default Comments;
