import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import CardInfo from 'src/components/ui/shared/cardInfo/CardInfo';
import LinkWithIcon from 'src/components/ui/shared/linkWithIcon/LinkWithIcon';
import TitleSection from 'src/components/ui/shared/titleSection/TitleSection';
import { TPublicationItem } from 'src/types/publication';
import styles from './publicationAndBooks.module.scss';
import PDF from 'src/assets/images/icons/pdf.svg';
import { BASE_URL } from 'src/axios/baseUrl';
import Button from 'src/components/ui/shared/button/Button';

interface IProps {
  title: string;
  cards: TPublicationItem[];
  textCardFooter?: string;
  link: string;
}

const MAX_BOOK_COUNT = 6;

const PublicationAndBooks = ({
  title,
  cards,
  textCardFooter,
  link,
}: IProps) => {
  const { t } = useTranslation('common');

  const lastSixCard = useMemo(
    () =>
      cards.length > MAX_BOOK_COUNT ? cards.slice(0, MAX_BOOK_COUNT) : cards,
    [cards],
  );

  return (
    <section className={styles.sectionPublication}>
      <div className={styles.titleBlock}>
        <TitleSection title={title} />
      </div>
      <div className={styles.body}>
        {lastSixCard?.map((card) => {
          return (
            <li key={card.id}>
              <Link to={`${BASE_URL}${card?.file?.url}` || ''} target='_blank'>
                <CardInfo
                  title={card?.name}
                  text={card?.description}
                  key={card.id}
                  textFooter={textCardFooter}
                >
                  <div className={styles.headCard}>
                    <p className={styles.date}>
                      <img src={PDF} alt='pdf' />
                    </p>
                    {/* <p className={`${styles.link}`}>
                      {format(card.file.uploaded_at)}
                    </p> */}
                  </div>
                </CardInfo>
              </Link>
            </li>
          );
        })}
      </div>
      <div className={styles.showAllLink}>
        <LinkWithIcon path={link} text={t('seeAll')} target='_parent' />
      </div>
      <div className={styles.buttonAll}>
        <Link to={link}>
          <Button icon>{t('seeAll')}</Button>
        </Link>
      </div>
    </section>
  );
};
export default PublicationAndBooks;
