import { Navigate, Outlet } from 'react-router-dom';
import { ProtectedRouteProps } from './types';

const ProtectedRoute = ({
  isAllowed,
  redirectPath,
  children,
}: ProtectedRouteProps) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return <>{children ?? <Outlet />}</>;
};

export default ProtectedRoute;
