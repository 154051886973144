import axios, { AxiosRequestConfig } from 'axios';
import { BASE_URL } from './baseUrl';

const config: AxiosRequestConfig = {
  baseURL: `${BASE_URL}/api`,
  timeout: 10000,
};

const instance = axios.create(config);
instance.interceptors.request.use((config) => {
  let lang = 'ru';
  if (localStorage.getItem('i18nextLng') === 'chi') {
    lang = 'zh-cn';
  } else {
    lang = localStorage.getItem('i18nextLng') || 'ru';
  }
  config.headers['Accept-Language'] = lang;
  return config;
});

export const { Get, Post, Put, Delete } = {
  Get: instance.get,
  Post: instance.post,
  Put: instance.put,
  Delete: instance.delete,
};

export default instance;
