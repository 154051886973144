import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import LinkWithIcon from 'src/components/ui/shared/linkWithIcon/LinkWithIcon';
import TitleSection from 'src/components/ui/shared/titleSection/TitleSection';
import styles from './currentService.module.scss';
import { TItemCategoryService } from 'src/types/categoriesServices';

interface IProps {
  serviceSet?: TItemCategoryService[];
}

const CurrentService = ({ serviceSet }: IProps) => {
  const { t } = useTranslation('common');

  const route = useLocation();

  return (
    <>
      <TitleSection title={`${t('mainDirections')}`} />
      <div className={styles.block}>
        {serviceSet?.map((item) => (
          <div className={styles.blockService} key={item.id}>
            <div className={styles.headService}>
              <LinkWithIcon
                target='_self'
                text={item.name}
                path={`${route.pathname}/${item.id}`}
              />
            </div>
            <div
              className={styles.bodyService}
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default CurrentService;
