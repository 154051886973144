import { useTranslation } from 'react-i18next';

const useFormattedDate = () => {
  const { i18n } = useTranslation();

  const format = (date: string) => {
    return new Date(date).toLocaleString(`${i18n.language}`, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };

  return { format };
};

export default useFormattedDate;
