import styles from './cardEmploye.module.scss';

interface Props {
  src: string;
  name: string;
  profession: string;
}

const CardEmployee = ({ src, name, profession }: Props) => (
  <article>
    <div className={styles.card}>
      <div className={styles.cardImage}>
        <img src={src} />
      </div>
    </div>
    <p className={styles.cardName}>{name}</p>
    <p className={styles.cardProfession}>{profession}</p>
  </article>
);

export default CardEmployee;
