import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCurrentCategoryReview,
  getCurrentReview,
  getReviews,
  getUnlockingReview,
} from 'src/api/review';

export const getReviewListCategoriesThunk = createAsyncThunk(
  'getReviewListCategories',
  async () => {
    try {
      const { data: reviewListCategories } = await getReviews();
      return reviewListCategories;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getCurrentCategoryReviewsThunk = createAsyncThunk(
  'getCurrentCategoryReviews',
  async (id: string) => {
    try {
      const { data: currentCategoryReviews } = await getCurrentCategoryReview(
        id,
      );
      return currentCategoryReviews;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getCurrentReviewThunk = createAsyncThunk(
  'getCurrentReview',
  async (id: string) => {
    try {
      const { data: reviewCurrent } = await getCurrentReview(id);
      return reviewCurrent;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getUnlockingReviewsThunk = createAsyncThunk(
  'getUnlockingReviewsThunk',
  async () => {
    try {
      const { data: currentCategoryReviews } = await getUnlockingReview();
      return currentCategoryReviews.results;
    } catch (e) {
      console.log(e);
    }
  },
);
