import { useTranslation } from 'react-i18next';
import Logo from '../../../../assets/images/logo/logoWhite.svg';
import Cross from '../../../../assets/images/icons/cross.svg';
import LinkMenu from '../../shared/entities/linkMenu/LinkMenu';
import LinksSocial from '../../shared/entities/linksSocial/LinksSocial';
import LanguageComponent from '../../shared/entities/languageComponent/LanguageComponent';
import NationalizationIcon from '../../../../assets/images/socialIcon/Nationalization.svg';
import Links from '../../../../navigation/links';
import sentence from '../../../../assets/images/socialIcon/SanctionsPortalIcon.svg';
import telegram from '../../../../assets/images/socialIcon/Telegram.svg';
import video from '../../../../assets/images/socialIcon/VideoIcon.svg';
import CareerIcon from '../../../../assets/images/socialIcon/CareerIcon.svg';
import MainLink from '../../shared/link/MainLink';
import routers from '../../../../navigation/routers';
import styles from './mobileMenu.module.scss';
import { useAppSelector } from 'src/redux/store';
import unlockingAssets from '../../../../assets/images/socialIcon/UnlockingAssets.svg';

interface Props {
  isOpen: boolean;
  onClose: (value: boolean) => void;
}

const MobileMenu = ({ isOpen = false, onClose }: Props) => {
  const classMenu = `${styles.menu} ${isOpen ? styles.menuVisible : ''}`;

  const { t } = useTranslation(['common']);

  const contacts = useAppSelector((state) => state.contacts.ruContacts);

  const LINKS = [
    {
      title: t('socialLinkHeader.nationalization'),
      path: Links.nationalization,
      icon: NationalizationIcon,
      alt: t('socialLinkHeader.nationalization'),
    },
    {
      title: t('socialLinkHeader.unlockingAssets'),
      path: Links.unlockingAssets || '',
      icon: unlockingAssets,
      alt: t('socialLinkHeader.unlockingAssets'),
    },
    {
      title: t('socialLinkHeader.sancPortal'),
      path: Links.sentence || '',
      icon: sentence,
      alt: t('socialLinkHeader.sancPortal'),
    },
    {
      title: t('socialLinkHeader.telegram'),
      path: contacts?.contacts_set[0]?.telegram || '',
      icon: telegram,
      alt: t('socialLinkHeader.telegram'),
    },
    {
      title: t('socialLinkHeader.videoChannel'),
      path: Links.video || '',
      icon: video,
      alt: t('socialLinkHeader.videoChannel'),
    },
    {
      title: t('socialLinkHeader.career'),
      path: Links.career,
      icon: CareerIcon,
      alt: t('socialLinkHeader.career'),
    },
  ];
  return (
    <div className={classMenu}>
      <div className={styles.menuHeader}>
        <MainLink path={routers.home} target='_parent'>
          <div className={styles.menuLogo}>
            <img src={Logo} alt='nektorov, saveliev' />
          </div>
        </MainLink>
        <button
          type='button'
          className={styles.menuClose}
          onClick={() => onClose(false)}
        >
          <img src={Cross} alt='close' />
        </button>
      </div>
      <div className={styles.menuBody}>
        <LinkMenu />
      </div>
      <div className={styles.menuLine} />
      <div className={styles.menuBodySocial}>
        <LinksSocial links={LINKS} />
      </div>
      <div className={styles.menuLine} />
      <div className={styles.menuBodyLang}>
        <LanguageComponent />
      </div>
    </div>
  );
};

export default MobileMenu;
