/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { SwiperSlide } from 'swiper/react';
import Slider from '../../shared/slider/Slider';
import TitleSection from '../../shared/titleSection/TitleSection';
import styles from './sliderRecommended.module.scss';
import { TRating } from 'src/types/rating';
import { BASE_URL } from 'src/axios/baseUrl';

interface IProps {
  rating: TRating[];
}

const SliderRecommended = ({ rating }: IProps) => {
  const { t } = useTranslation('home');

  return (
    <div className={styles.recommend}>
      <div>
        <TitleSection title={t('section.recommend')} />
      </div>
      <Slider>
        {rating?.map((item) => (
          <SwiperSlide key={item.id}>
            <div className={styles.slide}>
              <div className={styles.slideImage}>
                <img
                  src={`${BASE_URL}${item.banner?.url}`}
                  alt='comment from'
                />
              </div>
              <p>{item.name}</p>
            </div>
          </SwiperSlide>
        ))}
      </Slider>
    </div>
  );
};
export default SliderRecommended;
