import CurrentNews from 'src/components/currentNews/CurrentNews';
import Container from 'src/components/ui/shared/container/Container';
import { Helmet } from 'react-helmet-async';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CurrentNewsPage = () => {
  const { t } = useTranslation('common');

  return (
    <Container>
      <Helmet>
        <title>{t('pageTitles.pressCenter')}</title>
      </Helmet>
      <CurrentNews />
    </Container>
  );
};

export default CurrentNewsPage;
