import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import React from 'react';
import Lawsuit from 'src/components/lawsuit/Lawsuit';

const LawsuitPage = () => {
  const { t } = useTranslation('common');

  const description = t('meta.description.lawsuit');

  return (
    <>
      <Helmet>
        <title>{t('pageTitles.lawsuit')}</title>
        {description && <meta name='description' content={description} />}
      </Helmet>
      <Lawsuit />
    </>
  );
};
export default LawsuitPage;
