import LinkWithIcon from 'src/components/ui/shared/linkWithIcon/LinkWithIcon';
import TitleSection from 'src/components/ui/shared/titleSection/TitleSection';
import styles from './events.module.scss';
import CardInfo from 'src/components/ui/shared/cardInfo/CardInfo';
import Button from 'src/components/ui/shared/button/Button';
import { useTranslation } from 'react-i18next';
import routers from 'src/navigation/routers';
import { useFormattedDate } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import { TEventItem } from 'src/types/news';

interface IProps {
  events: TEventItem[];
  textCardFooter?: string;
  linkSeeAll: string;
}

const Events = ({ events, textCardFooter, linkSeeAll }: IProps) => {
  const { t } = useTranslation('common');
  const { format } = useFormattedDate();
  const navigate = useNavigate();

  const slicedEvents = events && events.slice(0, 6);

  return (
    <section className={styles.root}>
      {slicedEvents && slicedEvents.length > 0 && (
        <div className={styles.headingWrapper}>
          <TitleSection title={`${t('events')}`} />
        </div>
      )}
      <ul className={styles.contentList}>
        {slicedEvents?.map((event, index) => {
          return (
            <li
              className={styles.contentItem}
              onClick={() =>
                navigate(`${routers.pressCenter}/events/${event.id}`)
              }
              key={index}
            >
              <CardInfo
                textFooter={textCardFooter}
                title={event?.name}
                text={event?.additional_text}
              >
                <p>{format(event?.publication_date)}</p>
              </CardInfo>
            </li>
          );
        })}
      </ul>
      {slicedEvents && slicedEvents.length > 0 && (
        <>
          <div className={styles.showAllLink}>
            <LinkWithIcon
              path={linkSeeAll}
              text={t('seeAll')}
              target='_parent'
            />
          </div>
          <div
            className={styles.showAllButton}
            onClick={() => navigate(linkSeeAll)}
          >
            <Button icon>{t('seeAll')}</Button>
          </div>
        </>
      )}
    </section>
  );
};

export default Events;
