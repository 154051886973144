import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import newsSlice from './news';
import categoriesServicesSlice from './categoriesServices';
import teamSlice from './team';
import publicationSlice from './publication';
import reviewSlice from './review';
import sendFormSlice from './askForm';
import contactsSlice from './contacts';
import sendFormResumeSlice from './resume';
import aboutCompanySlice from './aboutCompany';
import ratingSlice from './rating';
import staticPagesSlice from './staticPages';

const rootReducer = combineReducers({
  news: newsSlice,
  categoriesServices: categoriesServicesSlice,
  team: teamSlice,
  publication: publicationSlice,
  review: reviewSlice,
  form: sendFormSlice,
  contacts: contactsSlice,
  resume: sendFormResumeSlice,
  aboutCompany: aboutCompanySlice,
  rating: ratingSlice,
  staticPages: staticPagesSlice,
});

const store = configureStore({
  reducer: rootReducer,
});

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
