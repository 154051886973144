/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AllCardContainer from 'src/components/ui/shared/allCardContainer/AllCardContainer';
import CardInfo from 'src/components/ui/shared/cardInfo/CardInfo';
import Container from 'src/components/ui/shared/container/Container';
import TitleSection from 'src/components/ui/shared/titleSection/TitleSection';
import {
  getAllBooksThunk,
  getAllPublicationThunk,
} from 'src/redux/publication/thunk';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import PDF from 'src/assets/images/icons/pdf.svg';
import styles from './publications.module.scss';
import { Link, useParams } from 'react-router-dom';
import { TPublicationItem } from 'src/types/publication';
import { BASE_URL } from 'src/axios/baseUrl';
import { Helmet } from 'react-helmet-async';

const PublicationsAllPage = () => {
  const { t, i18n } = useTranslation('common');
  const lastItem = useRef(null);
  const [isLoad, setIsLoad] = useState<boolean>();
  const [cards, setCards] = useState<Array<TPublicationItem>>([]);

  const dispatch = useAppDispatch();
  const params = useParams();
  const typeSection = params.unlocking ? 'actives_publication' : 'publication';

  const currentMetadata = useAppSelector((state) => {
    switch (params.type) {
      case 'publication':
        return state.publication.publicationAll;
      case 'book':
        return state.publication.booksAll;
    }
  });

  const currentItems = useAppSelector((state) => {
    switch (params.type) {
      case 'publication':
        return state.publication.publicationAll?.results;
      case 'book':
        return state.publication.booksAll?.results;
      default:
        return [];
    }
  });

  useEffect(() => {
    currentItems && setCards([...cards, ...currentItems]);
  }, [currentItems]);

  useEffect(() => {
    if (currentMetadata?.next !== null) {
      let pageMeta = '';
      const startPageStr = currentMetadata?.next.slice(
        currentMetadata.next.indexOf('page=') + 5,
      );
      if (startPageStr?.includes('&')) {
        const endPageStr = startPageStr.indexOf('&');
        pageMeta = startPageStr.slice(0, endPageStr);
      } else {
        pageMeta = startPageStr || '1';
      }
      if (isLoad) {
        if (pageMeta) {
          switch (params.type) {
            case 'publication':
              dispatch(
                getAllPublicationThunk({ page: +pageMeta, type: typeSection }),
              );
              break;
            case 'book':
              dispatch(
                getAllBooksThunk({ page: +pageMeta, type: typeSection }),
              );
              break;
          }
        }
      }
    }
  }, [isLoad]);

  useEffect(() => {
    if (lastItem.current) {
      const currentRef = lastItem.current;
      const scrollObserver = new IntersectionObserver((entries) => {
        if (!entries[0].isIntersecting) {
          setIsLoad(false);
        } else {
          setIsLoad(true);
        }
      });

      scrollObserver.observe(currentRef);
      return () => scrollObserver.unobserve(currentRef);
    }
  }, []);

  useEffect(() => {
    if (currentItems?.length === 0) {
      if (params.type === 'publication') {
        dispatch(getAllPublicationThunk({ type: typeSection }));
      } else if (params.type === 'book') {
        dispatch(getAllBooksThunk({ type: typeSection }));
      }
    }
  }, [i18n.language]);

  return (
    <Container isPaddingTop={false}>
      <Helmet>
        <title>{t('pageTitles.analytics')}</title>
      </Helmet>
      <div className={styles.content}>
        <TitleSection
          title={params.type === 'publication' ? t('publication') : t('books')}
        />
        <AllCardContainer>
          <>
            {cards?.map((card) => {
              return (
                <li key={card.id} className={styles.card}>
                  <Link
                    to={`${BASE_URL}${card?.file?.url}` || ''}
                    target='_blank'
                  >
                    <CardInfo title={card?.name} text={card?.description}>
                      <div className={styles.headCard}>
                        <p className={styles.date}>
                          <img src={PDF} alt='pdf' />
                        </p>
                      </div>
                    </CardInfo>
                  </Link>
                </li>
              );
            })}
          </>
          <div ref={lastItem} />
        </AllCardContainer>
      </div>
    </Container>
  );
};
export default PublicationsAllPage;
