import { useTranslation } from 'react-i18next';
import TitleSection from 'src/components/ui/shared/titleSection/TitleSection';
import styles from './functionBounds.module.scss';
import { ReactComponent as Arrow } from 'src/assets/images/icons/arrowGo.svg';

const FunctionBounds = () => {
  const { t } = useTranslation('bounds');

  const DATA = [
    { title: 'контроль исполнения эмитентом обязательств по облигациям;' },
    {
      title:
        'выявление обстоятельств, влекущих за собой нарушение прав и законных интересов владельцев облигаций;',
    },
    { title: 'исполнение решений общих собраний владельцев облигаций;' },
    { title: 'информирование владельцев облигаций;' },
    { title: 'защита прав и законных интересов владельцев облигаций;' },
    {
      title:
        'согласие на внесение изменений в решение о выпуске облигаций и в проспект облигаций.',
    },
  ];

  return (
    <section className={styles.function}>
      <TitleSection title={t('functionBounds')} />
      <div className={styles.functionBody}>
        {DATA?.map((item, index) => (
          <div key={index} className={styles.text}>
            <Arrow /> {item.title}
          </div>
        ))}
      </div>
    </section>
  );
};
export default FunctionBounds;
