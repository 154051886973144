/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import CardInfo from '../ui/shared/cardInfo/CardInfo';
import TitleSection from '../ui/shared/titleSection/TitleSection';
import AllCardContainer from '../ui/shared/allCardContainer/AllCardContainer';
import styles from './eventsAll.module.scss';
import MainCalendar from '../ui/widgets/calendar/Calendar';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { TCommentItem, TEventItem } from 'src/types/news';
import { useFormattedDate } from 'src/hooks';
import routers from 'src/navigation/routers';
import {
  getCommentsThunk,
  getEventsThunk,
  getNewsThunk,
} from 'src/redux/news/thunks';
import formatDateForApi from 'src/utils/formatDate';

// TODO: shitty component
const EventsAll = () => {
  const { t, i18n } = useTranslation('common');
  const now = new Date();

  const [isLoad, setIsLoad] = useState<boolean>();
  const nowLast = formatDateForApi(now);

  const [filterDate, setFilterDate] = useState({
    start_date: '2000-01-01',
    end_date: nowLast.end_date,
  });

  const [isFilter, setIsFilter] = useState<boolean | null>(null);

  const [allCurrentItems, setAllCurrentItems] = useState<
    Array<TEventItem | TCommentItem>
  >([]);

  useEffect(() => {
    setAllCurrentItems([]);
  }, [i18n.language]);

  // TODO: we really need this?
  const [cardsFilter, setCardsFilter] = useState<
    Array<TEventItem | TCommentItem>
  >([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { format } = useFormattedDate();
  const lastItem = useRef(null);
  const params = useParams();
  const location = useLocation();

  const typeActives = location.pathname.includes('unlocking');
  const typeLawsuit = location.pathname.includes('lawsuit');

  const currentItems = useAppSelector((state) => {
    switch (params.type) {
      case 'news':
        return state.news.news?.results;
      case 'events':
        return state.news.events?.results;
      case 'comments':
        return state.news.comments?.results;
      default:
        return [];
    }
  });

  useEffect(() => {
    let itemList: Array<TEventItem | TCommentItem> = currentItems ?? [];

    if (currentItems) {
      itemList = [...allCurrentItems, ...itemList];
    }

    setAllCurrentItems(itemList);
  }, [currentItems]);

  useEffect(() => {
    setCardsFilter(allCurrentItems);
  }, [allCurrentItems, i18n.language]);

  const currentMetadata = useAppSelector((state) => {
    switch (params.type) {
      case 'news':
        return state.news.news;
      case 'events':
        return state.news.events;
      case 'comments':
        return state.news.comments;
    }
  });

  const getCurrentNewsType = () => {
    if (typeActives) {
      return 'actives_news';
    } else if (typeLawsuit) {
      return 'lawsuit_news';
    } else {
      return 'event';
    }
  };

  const getCurrentMediaType = () => {
    if (typeActives) {
      return 'actives_media';
    } else if (typeLawsuit) {
      return 'lawsuit_media';
    } else {
      return 'media_comment';
    }
  };

  const getContentType = async (page = 1) => {
    setIsLoad(true);

    switch (params.type) {
      case 'news':
        await dispatch(
          getNewsThunk({
            is_event: 0,
            page,
            ...filterDate,
            typeActives: getCurrentNewsType(),
          }),
        );
        break;
      case 'events':
        await dispatch(
          getEventsThunk({
            is_event: 1,
            page,
            ...filterDate,
            typeActives: getCurrentNewsType(),
          }),
        );
        break;
      case 'comments':
        await dispatch(
          getCommentsThunk({
            page,
            ...filterDate,
            typeActives: getCurrentMediaType(),
          }),
        );
        break;
    }

    setIsLoad(false);
  };

  useEffect(() => {
    getContentType();
  }, [i18n.language, filterDate]);

  useEffect(() => {
    const currentRef = lastItem.current;

    if (!currentRef || isLoad || !currentMetadata?.next) return;

    const scrollObserver = new IntersectionObserver((entries) => {
      const nextUrl = new URL(currentMetadata.next as string);
      const nextParams = new URLSearchParams(nextUrl.search);
      const nextPage = +(nextParams.get('page') ?? 1);

      const hasIntersecting = entries[0].isIntersecting;

      if (hasIntersecting) {
        getContentType(nextPage);
      }
    });

    scrollObserver.observe(currentRef);

    return () => scrollObserver.unobserve(currentRef);
  }, [currentMetadata, filterDate, isLoad]);

  const getTitle = () => {
    switch (params.type) {
      case 'news':
        return t('newsEvent');
      case 'comments':
        return t('mediaComments');
      case 'events':
        return t('events');
      default:
        return '';
    }
  };

  function isComment(item: TCommentItem | TEventItem): item is TCommentItem {
    return 'team_member' in item;
  }

  const handleSeeAll = () => {
    setFilterDate({
      start_date: '2000-01-01',
      end_date: nowLast.end_date,
    });

    setIsFilter(false);
    setAllCurrentItems([]);
  };

  const onChangeMonth = (date: Date) => {
    const monthPeriod = formatDateForApi(date);

    setFilterDate({
      ...monthPeriod,
    });

    setIsFilter(true);
    setAllCurrentItems([]);
  };

  const currentMonth = new Date(filterDate.end_date);

  return (
    <div className={styles.content}>
      <TitleSection title={getTitle()} />
      {!typeActives && (
        <div className={styles.contentCalendare}>
          <MainCalendar date={currentMonth} setDate={onChangeMonth} />
          {isFilter && (
            <button onClick={handleSeeAll}>
              <span>{t('showAll')}</span>
            </button>
          )}
        </div>
      )}
      <AllCardContainer>
        {cardsFilter.map((card, index) => (
          <li
            key={`${card.name}-${index}`}
            onClick={() =>
              navigate(`${routers.pressCenter}/${params.type}/${card.id}`)
            }
          >
            <CardInfo
              title={card.name}
              text={
                isComment(card)
                  ? `${card?.team_member?.position} ${card?.team_member?.name}`
                  : card?.additional_text
              }
            >
              {isComment(card) ? (
                <p>{card.media.name}</p>
              ) : (
                <p>{format(card.publication_date)}</p>
              )}
            </CardInfo>
          </li>
        ))}
        <div ref={lastItem} />
      </AllCardContainer>
    </div>
  );
};
export default EventsAll;
