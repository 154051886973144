import { ReactNode } from 'react';
import 'swiper/swiper-bundle.css';
import { Swiper } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import styles from './slider.module.scss';

interface Props {
  delay?: number;
  slidesPerView?: number;
  isNavigation?: boolean;
  autoplay?: boolean;
  children: ReactNode;
  loop?: boolean;
}

const Slider = ({
  slidesPerView = 1,
  isNavigation = true,
  delay = 2500,
  autoplay = false,
  children,
  loop = false,
}: Props) => (
  <div className={styles.sliderBlock}>
    <Swiper
      spaceBetween={24}
      slidesPerView={slidesPerView}
      loop={loop}
      modules={[Navigation, Pagination, Autoplay]}
      navigation={isNavigation}
      speed={1000}
      pagination={{ clickable: true }}
      autoplay={
        autoplay
          ? {
              delay,
              disableOnInteraction: false,
            }
          : false
      }
    >
      {children}
    </Swiper>
  </div>
);

export default Slider;
