import styles from './description.module.scss';
import TitleSection from 'src/components/ui/shared/titleSection/TitleSection';
import { TAboutDescription } from 'src/types/aboutCompany';

interface IProps {
  description: TAboutDescription[];
}

const Description = ({ description }: IProps) => {
  // const DATA = [ FOR STATIC DATA
  //   {
  //     id: 0,
  //     title: t('oneTitle'),
  //     img: One,
  //     desc: (
  //       <Trans
  //         t={t}
  //         defaults={`${t('oneDesc')}`}
  //         components={{ bold: <p> </p> }}
  //       />
  //     ),
  //   },
  //   {
  //     id: 1,
  //     title: t('twoTitle'),
  //     img: Two,
  //     desc: (
  //       <Trans
  //         t={t}
  //         defaults={`${t('twoDesc')}`}
  //         components={{ bold: <p> </p> }}
  //       />
  //     ),
  //   },
  //   {
  //     id: 2,
  //     title: t('threeTitle'),
  //     img: Three,
  //     desc: (
  //       <Trans
  //         t={t}
  //         defaults={`${t('twhreeDesc')}`}
  //         components={{ bold: <p> </p> }}
  //       />
  //     ),
  //   },
  // ];

  return (
    <div className={styles.descriptionBlock}>
      <div>
        {description?.map((item) => (
          <div key={item.id} className={styles.itemDesc}>
            <img src={item.img} />
            <div>
              <TitleSection title={item.title} />
              <div dangerouslySetInnerHTML={{ __html: item.desc }} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Description;
