/* eslint-disable react-hooks/exhaustive-deps */
import Container from 'src/components/ui/shared/container/Container';
import News from 'src/components/pressCenter/news/News';
import Comments from 'src/components/pressCenter/comments/Comments';
import Events from 'src/components/pressCenter/events/Events';
import { useAppSelector } from 'src/redux/store';
import Loader from 'src/components/ui/shared/loader/Loader';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routers from 'src/navigation/routers';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const PressCenter = () => {
  const navigate = useNavigate();
  const [isLoadContent, setIsLoadContent] = useState<boolean>();
  const {
    loadingNews,
    loadingEvents,
    dataNews,
    dataEvents,
    dataComment,
    news,
    comments,
    events,
  } = useAppSelector((state) => ({
    loadingNews: state.news.loadingNews,
    loadingEvents: state.news.loadingEvents,
    dataNews: state.news.langDataNews,
    dataEvents: state.news.langDataEvents,
    dataComment: state.news.langDataComment,
    news: state.news.news?.results,
    comments: state.news.comments?.results,
    events: state.news.events?.results,
  }));
  const { t } = useTranslation('common');

  useEffect(() => {
    if (loadingNews || loadingEvents) {
      setIsLoadContent(true);
    } else {
      setIsLoadContent(false);
    }
  }, [loadingNews, loadingEvents]);

  useEffect(() => {
    if (
      dataNews === 'noData' &&
      dataEvents === 'noData' &&
      dataComment === 'noData'
    ) {
      navigate(routers.home);
    }
  }, [dataNews, dataEvents, dataComment]);

  return (
    <Container>
      <Helmet>
        <title>{t('pageTitles.pressCenter')}</title>
      </Helmet>
      {isLoadContent ? (
        <Loader />
      ) : (
        <>
          {news && (
            <News news={news} linkSeeAll={`${routers.pressCenter}/news`} />
          )}
          {events && (
            <Events
              events={events}
              linkSeeAll={`${routers.pressCenter}/events`}
            />
          )}
          {comments && (
            <Comments
              comments={comments}
              linkSeeAll={`${routers.pressCenter}/comments`}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default PressCenter;
