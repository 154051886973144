import { ReactNode } from 'react';
import { NavLink, RelativeRoutingType } from 'react-router-dom';
import styles from './navigateLink.module.scss';

interface Props {
  path: string;
  children: ReactNode;
  relative?: RelativeRoutingType;
  target?: string;
}

const NavigationLink = ({
  path,
  children,
  relative,
  target = '_blank',
}: Props) => (
  <NavLink
    to={path}
    relative={relative}
    target={target}
    className={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
  >
    {children}
  </NavLink>
);

export default NavigationLink;
