/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import AskLawyer from 'src/components/team/askLawyer/AskLawyer';
import InformationEmployee from 'src/components/team/employee/informationEmployee/InformationEmployee';
import EmployeeSlider from 'src/components/team/employee/slider/EmployeeSlider';
import Container from 'src/components/ui/shared/container/Container';
import SliderRecommended from 'src/components/ui/widgets/sliderRecommended/SliderRecommended';
import { getRatingHomeThunk } from 'src/redux/rating/thunk';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { getAllEmployesThunk, getEmployeeThunk } from 'src/redux/team/thunk';
import styles from './team.module.scss';
import Loader from 'src/components/ui/shared/loader/Loader';
import { Helmet } from 'react-helmet-async';

const Employee = () => {
  const { t, i18n } = useTranslation('common');
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { rating, employee, isLoading, error } = useAppSelector((state) => ({
    employee: state.team.employee,
    rating: state.rating.ratingHomeInfo,
    isLoading: state.team.loading,
    error: state.team.error,
  }));

  useEffect(() => {
    dispatch(getRatingHomeThunk());
  }, [i18n.language]);

  useEffect(() => {
    if (!params.id) return navigate('/404');

    dispatch(getEmployeeThunk(params.id));
  }, [params]);

  useEffect(() => {
    dispatch(getAllEmployesThunk());
  }, [i18n.language]);

  useEffect(() => {
    if (!error) return;

    navigate('/404');
  }, [error]);

  return (
    <Container isPaddingTop={false}>
      <Helmet>
        <title>{t('pageTitles.team')}</title>
      </Helmet>
      {isLoading && <Loader />}
      {employee && !isLoading && (
        <>
          <EmployeeSlider images={employee?.image_set} />
          <InformationEmployee currentEmploye={employee} />
          <div className={styles.recomend}>
            {rating.length > 0 && <SliderRecommended rating={rating} />}
          </div>
          <AskLawyer title={`${employee?.name_ask}`} />
        </>
      )}
    </Container>
  );
};
export default Employee;
