import { createSlice } from '@reduxjs/toolkit';
import { sendResumeFormThunk } from './thunk';

const initialState: {
  sendResume: string;
  loading: boolean;
  error: boolean;
} = {
  sendResume: '',
  loading: false,
  error: false,
};

const sendFormResumeSlice = createSlice({
  name: 'sendResumeForm',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(sendResumeFormThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.sendResume = action.payload || '';
    });

    builder.addCase(sendResumeFormThunk.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(sendResumeFormThunk.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default sendFormResumeSlice.reducer;
