import { ReactNode } from 'react';
import styles from './cardImageTextFooter.module.scss';
// card example Home section- Category

interface Props {
  image: string;
  children: ReactNode;
  textFooter: string;
  alt?: string;
}

const CardImageTextFooter = ({ image, children, textFooter, alt }: Props) => (
  <div className={styles.card}>
    <div className={styles.cardImage}>
      <img src={image} alt={alt} />
    </div>
    <div className={styles.cardText}>{children}</div>
    <p className={styles.cardTextFooter}>{textFooter}</p>
  </div>
);

export default CardImageTextFooter;
