import { createSlice } from '@reduxjs/toolkit';
import { sendAskFormThunk } from './thunk';

const initialState: {
  sendAsk: string;
  loading: boolean;
  error: boolean;
} = {
  sendAsk: '',
  loading: false,
  error: false,
};

const sendFormSlice = createSlice({
  name: 'sendForm',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(sendAskFormThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.sendAsk = action.payload || '';
    });
    builder.addCase(sendAskFormThunk.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(sendAskFormThunk.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default sendFormSlice.reducer;
