import { Get } from 'src/axios';
import { TReview, TReviewCategories, TUnlockedReview } from 'src/types/review';

const getReviews = () => Get<TReviewCategories[]>('/news/review_category/');

const getCurrentCategoryReview = (category_id: string) =>
  Get<TReview[]>('/news/review/', {
    params: { category_id: category_id },
  });

const getCurrentReview = (id: string) => Get<TReview>(`/news/review/${id}/`);

const getUnlockingReview = () => Get<TUnlockedReview>('/news/actives_review/');

export {
  getReviews,
  getCurrentReview,
  getCurrentCategoryReview,
  getUnlockingReview,
};
