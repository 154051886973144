export default {
  sentence: 'https://sanctions.nsplaw.com/sanctions',
  telegram: 'https://t.me/joinchat/Y0F_o4vuo3YxYWQy',
  video: 'https://www.youtube.com/channel/UCuHMA6ZFJtYQpbfNAGPOKTQ',
  career: '/career',
  watsapp:
    'https://api.whatsapp.com/send/?phone=%2B79265733991&text&type=phone_number&app_absent=0',
  unlockingAssets: 'https://assets-unlocking.nsplaw.com',
  nationalization: 'https://nationalization.nsplaw.com',
};
