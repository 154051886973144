import { useNavigate } from 'react-router-dom';
import routers from 'src/navigation/routers';
import styles from './review.module.scss';
import { TReviewCategories } from 'src/types/review';

interface IProps {
  cards: TReviewCategories[];
}

const Review = ({ cards }: IProps) => {
  const navigate = useNavigate();

  return (
    <section>
      <div
        className={cards && cards?.length > 5 ? styles.body : styles.bodyMini}
      >
        {cards?.map((card, index) => {
          const reviewCardClass =
            styles.cardReview +
            (index % 2 === 0 ? ' ' + styles.cardReviewPurple : '');

          return (
            <li
              key={card.id}
              onClick={() =>
                navigate(`${routers.analytics}/review/all/${card.id}`)
              }
            >
              <div className={reviewCardClass}>
                <h5>{card?.name}</h5>
              </div>
            </li>
          );
        })}
      </div>
    </section>
  );
};
export default Review;
