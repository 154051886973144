import { createSlice } from '@reduxjs/toolkit';
import { TEmployee } from 'src/types/shared';
import { getAllEmployesThunk, getEmployeeThunk } from './thunk';

const initialState: {
  teamEmployes: TEmployee[];
  loading: boolean;
  employee: TEmployee | null;
  error: string | null;
} = {
  teamEmployes: [],
  loading: false,
  employee: null,
  error: null,
};

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllEmployesThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.teamEmployes = action.payload || [];
    });
    builder.addCase(getAllEmployesThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEmployeeThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEmployeeThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.employee = action.payload ?? null;
    });
    builder.addCase(getEmployeeThunk.rejected, (state) => {
      state.loading = false;
      state.error = 'not_found';
    });
  },
});

export default teamSlice.reducer;
