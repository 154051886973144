import { useState, ReactNode, useEffect } from 'react';
import Arrow from '../../../../assets/images/icons/arrow.svg';
import styles from './accordion.module.scss';

interface Props {
  title: string | ReactNode;
  children: ReactNode;
  height?: boolean;
  border?: boolean;
  countList?: number;
  isShow?: boolean;
}

const Accordion = ({
  title,
  children,
  height = true,
  border = false,
  countList = 8,
  isShow = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isShow);
  }, [isShow]);

  const classAccordion = `${
    isOpen
      ? styles.bodyAccordionOpen
      : height
      ? styles.bodyAccordion
      : styles.bodyAccordionFullHeight
  }`;
  const classArrow = `${styles.arrow} ${isOpen ? styles.arrowOpen : ''}`;

  return (
    <div className={border ? styles.headerAccordionBorder : ''}>
      <button
        type='button'
        className={styles.headerAccordion}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className={styles.title}>{title}</p>
        <div className={classArrow}>
          <img src={Arrow} alt='arrow' />
        </div>
      </button>
      <div
        style={
          isOpen
            ? { height: height ? countList * 40 + 'px' : '100%' }
            : { height: '0px' }
        }
        className={classAccordion}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
