import { useState, DragEvent, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './dragAndDrop.module.scss';

interface IProps {
  file?: File | null;
  setFile: (file: File) => void;
  isValid: boolean;
}

const DragAndDrop = ({ file, setFile, isValid }: IProps) => {
  const [isDrag, setIsDrag] = useState(false);
  const formats = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'text/plain',
  ];
  const [isFormat, setIsformat] = useState<boolean>();
  const classDrag = isDrag
    ? `${styles.dropArea} ${styles.dropAreaDrag}`
    : isValid
    ? `${styles.dropArea} `
    : `${styles.dropArea} ${styles.dropAreaNoValid}`;

  const { t } = useTranslation('common');

  const dragStartHandler = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    setIsDrag(true);
  };

  const dragLeaveHandler = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDrag(false);
  };

  const onDropHandler = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = [...e.dataTransfer.files];
    if (formats.includes(files[0].type)) {
      setIsformat(true);
      setFile(files[0]);
    } else {
      setIsformat(false);
    }
    setIsDrag(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file && formats.includes(file.type)) {
      setFile(file);
      setIsformat(true);
    } else {
      setIsformat(false);
    }
  };

  return (
    <div>
      <div className={classDrag}>
        <input
          className={styles.upload}
          type='file'
          accept='.pdf,.doc,.docx,.txt,'
          title=' '
          onDragStart={(e) => dragStartHandler(e)}
          onDragLeave={(e) => dragLeaveHandler(e)}
          onDragOver={(e) => dragStartHandler(e)}
          onDrop={(e) => onDropHandler(e)}
          onChange={handleChange}
          name='file'
        />
        {t('file.transfer')}
        .pdf .doc .docx .txt
      </div>

      <div className={styles.uploadedFile}>
        <div>
          {isFormat ? (
            <>
              <p className={styles.title}>{t('file.uploaded')}:</p>
              <p>{file?.name}</p>
            </>
          ) : (
            <p className={styles.titleError}>{t('file.errorFormat')}</p>
          )}
        </div>
      </div>
    </div>
  );
};
export default DragAndDrop;
