import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEmployee, getTeamEmployes } from 'src/api/team';

export const getAllEmployesThunk = createAsyncThunk(
  'getAllEmployes',
  async () => {
    try {
      const { data: teamEmployes } = await getTeamEmployes();
      return teamEmployes;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getEmployeeThunk = createAsyncThunk(
  'getEmployee',
  async (id: string) => {
    try {
      const { data: employee } = await getEmployee(id);

      return employee;
    } catch (e) {
      console.error(e);

      throw e;
    }
  },
);
