import i18n, { InitOptions } from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const IsDevMode = process.env.NODE_ENV === 'development';

const options: InitOptions = {
  fallbackLng: 'ru',
  debug: IsDevMode,
  detection: {
    order: ['subdomain'],
  },
  interpolation: { escapeValue: false },
};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(options);

export default i18n;
