import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BASE_URL } from 'src/axios/baseUrl';
import Accordion from 'src/components/ui/shared/accordion/Accordion';
import Title from 'src/components/ui/shared/title/Title';
import { TEmployee } from 'src/types/shared';
import styles from './informationEmployee.module.scss';
import { ReactComponent as Arrow } from 'src/assets/images/icons/arrow.svg';

interface IProps {
  currentEmploye?: TEmployee;
}

const InformationEmployee = ({ currentEmploye }: IProps) => {
  const { t } = useTranslation('team');
  const [isShowAll, setIsShowAll] = useState(false);

  const classArrow = `${styles.arrow} ${isShowAll ? styles.arrowOpen : ''}`;

  return (
    <section className={styles.informationSection}>
      <div className={styles.informationDescription}>
        <Title title={`${currentEmploye?.name}`} />
        <p className={styles.informationPosition}>{currentEmploye?.position}</p>
        {currentEmploye && (
          <Link to={`mailto:${currentEmploye.email}`}>
            <p className={styles.email}>{currentEmploye?.email}</p>
          </Link>
        )}
      </div>

      {currentEmploye && (
        <div className={styles.informationDescription}>
          <button
            className={styles.showAll}
            onClick={() => setIsShowAll(!isShowAll)}
          >
            {t('showAll')}{' '}
            <div className={classArrow}>
              <Arrow />
            </div>
          </button>
          {currentEmploye?.description.length > 0 && (
            <Accordion
              isShow={isShowAll}
              title={t('infoEmployee.specialization')}
              height={false}
            >
              <div
                className={styles.specialization}
                dangerouslySetInnerHTML={{
                  __html: currentEmploye?.description ?? '',
                }}
              />
            </Accordion>
          )}
          {currentEmploye?.rating_set.length > 0 && (
            <Accordion
              title={t('infoEmployee.ratings')}
              height={false}
              isShow={isShowAll}
            >
              {currentEmploye?.rating_set?.map((item) => {
                return (
                  <p className={styles.rating} key={item.id}>
                    {item.text}
                  </p>
                );
              })}
            </Accordion>
          )}
          {currentEmploye?.education.length > 0 && (
            <Accordion
              title={t('infoEmployee.education')}
              height={false}
              isShow={isShowAll}
            >
              <div
                className={styles.specialization}
                dangerouslySetInnerHTML={{
                  __html: currentEmploye?.education ?? '',
                }}
              />
            </Accordion>
          )}
          {currentEmploye?.book_set.length > 0 && (
            <Accordion
              title={t('infoEmployee.books')}
              height={false}
              isShow={isShowAll}
            >
              {currentEmploye?.book_set?.map((item) => {
                const linkUrl = item.file
                  ? BASE_URL + item?.file?.url
                  : item.link ?? '/#';

                return (
                  <p className={styles.rating} key={item.id}>
                    <Link to={linkUrl} target='_blank'>
                      {item.name}
                    </Link>
                  </p>
                );
              })}
            </Accordion>
          )}
          {currentEmploye?.portfolio && (
            <Accordion
              title={t('infoEmployee.expertActivity')}
              height={false}
              isShow={isShowAll}
            >
              <div
                className={styles.specialization}
                dangerouslySetInnerHTML={{
                  __html: currentEmploye?.portfolio ?? '',
                }}
              />
            </Accordion>
          )}
          {currentEmploye?.hobby.length > 0 && (
            <Accordion
              title={t('infoEmployee.interest')}
              height={false}
              isShow={isShowAll}
            >
              <div
                className={styles.specialization}
                dangerouslySetInnerHTML={{
                  __html: currentEmploye?.hobby ?? '',
                }}
              />
            </Accordion>
          )}
          {currentEmploye?.languages.length > 0 && (
            <Accordion
              title={t('infoEmployee.languages')}
              height={false}
              isShow={isShowAll}
            >
              <div
                className={styles.specialization}
                dangerouslySetInnerHTML={{
                  __html: currentEmploye?.languages ?? '',
                }}
              />
            </Accordion>
          )}
        </div>
      )}
    </section>
  );
};
export default InformationEmployee;
