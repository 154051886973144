import { NavLink, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import Container from 'src/components/ui/shared/container/Container';
import styles from './review.module.scss';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { getCurrentReviewThunk } from 'src/redux/review/thunk';
import NewsBlock from 'src/components/ui/widgets/newsBlock/NewsBlock';
import ContainerFlex from 'src/components/ui/shared/containerFlex/ContainerFlex';
import { useTranslation } from 'react-i18next';
import routers from 'src/navigation/routers';
import NewsContent from 'src/components/currentNews/shared/newsContent/NewsContent';
import Loader from 'src/components/ui/shared/loader/Loader';
import { Helmet } from 'react-helmet-async';

const CurrentReview = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');

  const { review, isLoading } = useAppSelector((state) => ({
    review: state.review.reviewCurrent,
    isLoading: state.review.loading,
  }));

  useEffect(() => {
    if (params.id) {
      dispatch(getCurrentReviewThunk(params.id));
    }
  }, [dispatch, params.id]);

  return (
    <Container className={styles.content}>
      <Helmet>
        <title>{t('pageTitles.analytics')}</title>
      </Helmet>
      <p className={styles.breadcrumbs}>
        <NavLink to={routers.analytics}>
          {t('navigationLinks.analytics').toUpperCase()}
        </NavLink>{' '}
        / {`${t('bankruptcyTitle')}`}
      </p>
      <ContainerFlex
        panelLeft={
          isLoading ? (
            <Loader />
          ) : (
            review && <NewsContent currentNews={review} />
          )
        }
        panelRight={
          review?.related_events && <NewsBlock news={review?.related_events} />
        }
      />
    </Container>
  );
};
export default CurrentReview;
