import { createAsyncThunk } from '@reduxjs/toolkit';
import { getContacts } from 'src/api/contacts';

export const getContactsThunk = createAsyncThunk('getContacts', async () => {
  try {
    const { data: contacts } = await getContacts();
    return contacts;
  } catch (e) {
    console.log(e);
  }
});
