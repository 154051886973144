import TitleSection from 'src/components/ui/shared/titleSection/TitleSection';
import styles from './basicServices.module.scss';

interface IProps {
  title: string;
  serviceList: string[];
}

const BasicServices = ({ title, serviceList }: IProps) => {
  return (
    <section>
      {title && <TitleSection title={title} />}
      <div className={styles.basicBody}>
        {serviceList?.map((item, index) => (
          <div key={index} className={styles.basicItem}>
            <p>{item}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
export default BasicServices;
