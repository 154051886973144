/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Title from 'src/components/ui/shared/title/Title';
import { getInformationCompanyThunk } from 'src/redux/aboutCompany/thunk';
import styles from './informationSection.module.scss';
import { useAppDispatch, useAppSelector } from 'src/redux/store';

const InformationSection = () => {
  const { t, i18n } = useTranslation('common');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getInformationCompanyThunk());
  }, [i18n.language]);

  const { aboutCompanyInfo } = useAppSelector((state) => ({
    aboutCompanyInfo: state.aboutCompany.aboutCompanyInfo,
  }));

  return (
    <section className={styles.assetsInformation}>
      <Title title={`${t('newsLawsuit')}`} />
      {aboutCompanyInfo?.lawsuit_text && (
        <div
          className={styles.assetsText}
          dangerouslySetInnerHTML={{
            __html: aboutCompanyInfo?.lawsuit_text,
          }}
        />
      )}
    </section>
  );
};
export default InformationSection;
