/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'src/components/ui/shared/container/Container';
import TitleSection from 'src/components/ui/shared/titleSection/TitleSection';
import MainCalendar from 'src/components/ui/widgets/calendar/Calendar';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import styles from './review.module.scss';
import { useLocation, useParams } from 'react-router-dom';
import { TReview } from 'src/types/review';
import {
  getCurrentCategoryReviewsThunk,
  getUnlockingReviewsThunk,
} from 'src/redux/review/thunk';
import Loader from 'src/components/ui/shared/loader/Loader';
import CardsReview from './CardsReview';
import { Helmet } from 'react-helmet-async';

const ReviewsAllPage = () => {
  const { t, i18n } = useTranslation('common');

  const dispatch = useAppDispatch();
  const params = useParams();
  const location = useLocation();

  const [cards, setCards] = useState<TReview[]>([]);
  const [value, setValue] = useState(new Date());

  const { currentCategoryReviews, isLoadingAll } = useAppSelector((state) => ({
    currentCategoryReviews: state.review.currentCategoryReviews,
    isLoadingAll: state.review.loading,
  }));

  useEffect(() => {
    if (location.pathname.includes('unlocking')) {
      dispatch(getUnlockingReviewsThunk());
    } else {
      params.id && dispatch(getCurrentCategoryReviewsThunk(params.id));
    }
  }, [i18n.language, params.id]);

  useEffect(() => {
    setCards(currentCategoryReviews);
  }, [dispatch, i18n.language, currentCategoryReviews]);

  useEffect(() => {
    const year = value.getFullYear();
    const month = value.getMonth();
    const resultCard = currentCategoryReviews.filter(
      (card) =>
        new Date(card.publication_date).getMonth() === month &&
        new Date(card.publication_date).getFullYear() === year,
    );

    setCards(resultCard);
  }, [value]);

  return (
    <Container isPaddingTop={false}>
      <Helmet>
        <title>{t('pageTitles.analytics')}</title>
      </Helmet>
      <div className={styles.content}>
        <TitleSection
          title={
            currentCategoryReviews[0]?.category?.name || t('bankruptcyTitle')
          }
        />
        {!location.pathname.includes('unlocking') && (
          <div className={styles.contentCalendare}>
            <MainCalendar date={value} setDate={setValue} />
            {cards.length !== currentCategoryReviews.length && (
              <button onClick={() => setCards(currentCategoryReviews ?? [])}>
                <span>{t('showAll')}</span>
              </button>
            )}
          </div>
        )}
        <div>
          {isLoadingAll ? (
            <Loader />
          ) : (
            <>
              <CardsReview cardsReview={cards} />
            </>
          )}
        </div>
      </div>
    </Container>
  );
};
export default ReviewsAllPage;
