import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICategory,
  ICategoryServices,
  ICurrentServiceCategory,
  IProjectService,
} from 'src/types/categoriesServices';
import { IDocument, TCrumbs } from 'src/types/shared';
import {
  getAllCategoriesThunk,
  getCategoryAllServicesThunk,
  getCurrentServiceThunk,
  getObligationServicesThunk,
  getProjectCategoryThunk,
} from './thunk';

const initialState: {
  categories: ICategory[];
  categoryServices: ICategoryServices | null;
  currentServiceCategory: ICurrentServiceCategory | null;
  projectServices: IProjectService[];
  obligationServices: IDocument[];
  loading: boolean;
  breadCrumbs: TCrumbs[];
} = {
  categories: [],
  categoryServices: null,
  currentServiceCategory: null,
  projectServices: [],
  obligationServices: [],
  loading: false,
  breadCrumbs: [],
};

const categoriesServicesSlice = createSlice({
  name: 'categoriesServices',
  initialState,
  reducers: {
    setBreadCrumbs: (state, action: PayloadAction<TCrumbs[]>) => {
      state.breadCrumbs = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllCategoriesThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = action.payload || [];
    });
    builder.addCase(getAllCategoriesThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCategoryAllServicesThunk.fulfilled, (state, action) => {
      state.categoryServices = action.payload || null;
    });
    builder.addCase(getCurrentServiceThunk.fulfilled, (state, action) => {
      state.currentServiceCategory = action.payload || null;
    });
    builder.addCase(getProjectCategoryThunk.fulfilled, (state, action) => {
      state.projectServices = action.payload || [];
    });
    builder.addCase(getObligationServicesThunk.fulfilled, (state, action) => {
      state.obligationServices = action.payload || [];
    });
  },
});

export const { setBreadCrumbs } = categoriesServicesSlice.actions;
export default categoriesServicesSlice.reducer;
