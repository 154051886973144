import { useTranslation } from 'react-i18next';
import ServicesList from 'src/components/services/servicesList/ServicesList';
import Container from 'src/components/ui/shared/container/Container';
import ContainerFlex from 'src/components/ui/shared/containerFlex/ContainerFlex';
import Title from 'src/components/ui/shared/title/Title';
import NewsBlock from 'src/components/ui/widgets/newsBlock/NewsBlock';
import { useAppSelector } from 'src/redux/store';
import styles from './services.module.scss';
import { Helmet } from 'react-helmet-async';
import React from 'react';

const Services = () => {
  const { t } = useTranslation('common');
  const news = useAppSelector((state) => state.news.news?.results);

  return (
    <Container className={styles.services}>
      <Helmet>
        <title>{t('pageTitles.services')}</title>
      </Helmet>
      <Title title={`${t('services')}`} />
      <ContainerFlex
        panelLeft={<ServicesList />}
        panelRight={<NewsBlock news={news} />}
      />
    </Container>
  );
};
export default Services;
