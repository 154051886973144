import ExternalHeader from './external/ExternalHeader';
import InternalHeader from './internal/InternalHeader';

interface Props {
  sticky?: boolean;
}

const Header = ({ sticky = false }: Props) => (
  <header>
    <ExternalHeader isVisible={sticky} />
    <InternalHeader sticky={sticky} />
  </header>
);

export default Header;
