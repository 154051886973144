import { Trans, useTranslation } from 'react-i18next';
import LinksSocial from '../ui/shared/entities/linksSocial/LinksSocial';
import styles from './contactComponent.module.scss';
import Telegram from '../../assets/images/socialIcon/TelegramColor.svg';
import Whatsapp from '../../assets/images/socialIcon/Whatsapp.svg';
import Messenger from '../../assets/images/socialIcon/MessengerIcon.svg';
import { TContact } from 'src/types/contacts';

interface IProps {
  addressInformation?: TContact;
}

const ContactComponent = ({ addressInformation }: IProps) => {
  const { t } = useTranslation('common');

  const LINKSOCIAL = [
    {
      path: addressInformation?.contacts_set[0]?.telegram || '',
      icon: Telegram,
      alt: 'Telegram',
      title: '',
    },
    {
      path: addressInformation?.contacts_set[0]?.whatsapp || '',
      icon: Whatsapp,
      alt: 'Whatsapp',
      title: '',
    },
    {
      path: addressInformation?.contacts_set[0]?.messenger || '',
      icon: Messenger,
      alt: 'Carrer',
      title: '',
    },
  ];

  const phoneNumber = addressInformation?.contacts_set[0]?.phone?.replace(
    /[^+\d]/g,
    '',
  );

  return (
    <div className={styles.contactSocialBlock}>
      <h2>{addressInformation?.contacts_set[0]?.address}</h2>
      <Trans
        t={t}
        defaults={`${t('contactInfo.fullFour')}`}
        values={{
          valuePhone: addressInformation?.contacts_set[0]?.phone,
          valueEmail: addressInformation?.contacts_set[0]?.email,
        }}
        components={{
          italic: (
            <a href={`mailto:${addressInformation?.contacts_set[0]?.email}`}>
              {/* {addressInformation?.contacts_set[0]?.email} */}
            </a>
          ),
          bold: <p />,
          strong: <a href={`tel:${phoneNumber}`}></a>,
        }}
        // components={{ bold: <p />, italic: <a /> }}
      />

      <div className={styles.contactsSocial}>
        <LinksSocial links={LINKSOCIAL} />
      </div>
    </div>
  );
};
export default ContactComponent;
