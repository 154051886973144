import { ChangeEvent, forwardRef, LegacyRef } from 'react';
import styles from './checkbox.module.scss';

interface Props {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  checked?: boolean;
}

const Checkbox = (
  { onChange, label = '', checked = false }: Props,
  ref: LegacyRef<any>,
) => (
  <label className={styles.checkbox}>
    <p className={styles.checkboxLabel}>{label}</p>
    <input
      type='checkbox'
      className={styles.checkboxInput}
      onChange={(event) => onChange(event)}
      value='checkbox'
      checked={checked}
      ref={ref}
    />
    <span className={styles.checkboxCheckmark} />
  </label>
);

export default forwardRef(Checkbox);
