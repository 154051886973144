/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import CardInfo from '../../ui/shared/cardInfo/CardInfo';
import MainLink from '../../ui/shared/link/MainLink';
import TitleSection from '../../ui/shared/titleSection/TitleSection';
import ImgRight from '../../../assets/images/home/actualRight.webp';
import ImgTop from '../../../assets/images/home/actualTop.webp';
import styles from './actual.module.scss';
import { useAppSelector } from 'src/redux/store';
import { useFormattedDate } from 'src/hooks';
import routers from 'src/navigation/routers';
import { BASE_URL } from 'src/axios/baseUrl';
import { Link } from 'react-router-dom';
import { ReactComponent as PDF } from '../../../assets/images/icons/pdf.svg';

const Actual = () => {
  const { t } = useTranslation(['home', 'common']);

  const { format } = useFormattedDate();

  const { actualNews } = useAppSelector((state) => ({
    actualNews: state.news.actualNews?.results,
  }));

  const getTypeNews = (item: number) => {
    const isNewsListEmpty =
      !actualNews || (Array.isArray(actualNews) && actualNews.length === 0);

    if (isNewsListEmpty) return;

    const arrayNews = Object.entries(actualNews[item]);
    const type = arrayNews.find(
      ([key, value]) =>
        value !== null && key !== 'id' && key !== 'file' && key !== 'language',
    );

    return type && type[0];
  };

  const getPath = (index: number) => {
    if (actualNews) {
      switch (getTypeNews(index)) {
        case 'event':
          if (actualNews[index]?.event?.is_event) {
            return `${routers.pressCenter}/events/${actualNews[index]?.event?.id}`;
          } else {
            return `${routers.pressCenter}/news/${actualNews[index]?.event?.id}`;
          }
        case 'media_comment':
          return `${routers.pressCenter}/comments/${actualNews[index]?.media_comment?.id}`;
        case 'publication':
          return (
            `${BASE_URL}${actualNews[index]?.publication?.file?.url}` ||
            `${routers.analytics}/book/`
          );
        case 'review':
          return (
            `${routers.analytics}/review/${actualNews[index]?.review?.id}` ||
            `${routers.analytics}/review/`
          );
        default:
          return `${routers.pressCenter}/news`;
      }
    }
  };

  const getCategorySectionLink = (index: number) => {
    if (actualNews) {
      switch (getTypeNews(index)) {
        case 'event':
          if (actualNews[index]?.event?.is_event) {
            return `${routers.pressCenter}/events/`;
          } else {
            return `${routers.pressCenter}/news/`;
          }
        case 'media_comment':
          return `${routers.pressCenter}/comments/`;
        case 'publication':
          if (actualNews[index]?.publication?.is_book) {
            return `${routers.analytics}/book/`;
          } else {
            return `${routers.analytics}/publication/`;
          }
        case 'review':
          return `${routers.analytics}`;
      }
    }
  };

  const getTitle = (index: number) => {
    if (actualNews) {
      switch (getTypeNews(index)) {
        case 'event':
          return actualNews[index]?.event?.name;
        case 'media_comment':
          return actualNews[index]?.media_comment?.name;
        case 'publication':
          return actualNews[index]?.publication?.name;
        case 'review':
          return actualNews[index]?.review?.name;
      }
    }
  };

  const getText = (index: number) => {
    if (actualNews) {
      switch (getTypeNews(index)) {
        case 'event':
          return actualNews[index]?.event?.media_text;
        case 'media_comment':
          return actualNews[index]?.media_comment?.media_text;
        case 'publication':
          return actualNews[index]?.publication?.description;
      }
    }
  };

  const getCategorySectionText = (index: number) => {
    if (actualNews) {
      switch (getTypeNews(index)) {
        case 'event':
          if (actualNews[index]?.event?.is_event) {
            return (
              <>
                <span>NsP </span>| {`${t('common:events')}`}
              </>
            );
          } else {
            return (
              <>
                <span>NsP&nbsp;</span>| {`${t('common:news')}`}
              </>
            );
          }
        case 'media_comment':
          return (
            <>
              <span>NsP&nbsp;</span>| {`${t('common:mediaComments')}`}
            </>
          );
        case 'publication':
          if (actualNews[index]?.publication?.is_book) {
            return (
              <>
                <span>NsP </span>| {`${t('common:books')}`}
              </>
            );
          } else {
            return (
              <>
                <span>NsP&nbsp;</span>| {`${t('common:publication')}`}
              </>
            );
          }
        case 'review':
          if (actualNews[index].review?.category.name) {
            return (
              <>
                <span>NsP&nbsp;</span>| {`${t('common:analytics')}`}
              </>
            );
          }
      }
    }
  };

  const getLeftTopContent = (index: number) => {
    if (actualNews) {
      switch (getTypeNews(index)) {
        case 'event':
          return format(actualNews[index]?.event?.publication_date || '');
        case 'media_comment':
          return actualNews[index]?.media_comment?.media?.name;
        case 'publication':
          return <PDF />;
        case 'review':
          return format(actualNews[index].review?.publication_date || '');
      }
    }
  };

  return (
    <section className={styles.actual}>
      <TitleSection title={t('section.actual')} />
      <div className={styles.actualBody}>
        {actualNews && (
          <div className={styles.actualRow}>
            <Link
              className={styles.first}
              to={getPath(0) || `${routers.pressCenter}`}
              target='_blank'
            >
              <CardInfo
                imageSrc={
                  actualNews[0]?.file?.url
                    ? `${BASE_URL}${actualNews[0]?.file?.url}`
                    : ImgRight
                }
                text={getText(0)}
                title={getTitle(0)}
              >
                <div className={styles.headCard}>
                  <p className={styles.date}>{getLeftTopContent(0)}</p>
                  <p
                    className={styles.link}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <MainLink
                      target='_self'
                      path={
                        getCategorySectionLink(0) ||
                        `${routers.pressCenter}/news`
                      }
                    >
                      {getCategorySectionText(0)}
                    </MainLink>
                  </p>
                </div>
              </CardInfo>
            </Link>
            <Link
              className={styles.second}
              to={getPath(1) || `${routers.pressCenter}`}
              target='_blank'
            >
              <CardInfo color='accent' text={getText(1)} title={getTitle(1)}>
                <div className={styles.headCard}>
                  <p className={styles.date}>{getLeftTopContent(1)}</p>
                  <p
                    className={`${styles.link}  ${styles.accent}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <MainLink
                      target='_self'
                      path={
                        getCategorySectionLink(1) || `${routers.pressCenter}`
                      }
                    >
                      {getCategorySectionText(1)}
                    </MainLink>
                  </p>
                </div>
              </CardInfo>
            </Link>

            <Link
              className={styles.second}
              to={getPath(2) || `${routers.pressCenter}`}
              target='_blank'
            >
              <CardInfo color='grey' title={getTitle(2)} text={getText(2)}>
                <div className={styles.headCard}>
                  <p className={styles.date}>{getLeftTopContent(2)}</p>
                  <p
                    className={`${styles.link}  ${styles.accent}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <MainLink
                      target='_self'
                      path={
                        getCategorySectionLink(2) || `${routers.pressCenter}`
                      }
                    >
                      {getCategorySectionText(2)}
                    </MainLink>
                  </p>
                </div>
              </CardInfo>
            </Link>

            <div className={styles.columnInRow}>
              <Link to={getPath(3) || `${routers.pressCenter}`} target='_blank'>
                <CardInfo title={getTitle(3)} text={getText(3)}>
                  <div className={styles.headCard}>
                    <p className={styles.date}>{getLeftTopContent(3)}</p>

                    <p
                      className={`${styles.link}`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <MainLink
                        target='_self'
                        path={
                          getCategorySectionLink(3) || `${routers.pressCenter}`
                        }
                      >
                        {getCategorySectionText(3)}
                      </MainLink>
                    </p>
                  </div>
                </CardInfo>
              </Link>
              <Link
                className={styles.predLast}
                to={getPath(4) || `${routers.pressCenter}`}
                target='_blank'
              >
                <CardInfo color='accent' title={getTitle(4)} text={getText(4)}>
                  <div className={styles.headCard}>
                    <p className={styles.date}>{getLeftTopContent(4)}</p>
                    <p
                      className={`${styles.link}  ${styles.accent}`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <MainLink
                        target='_self'
                        path={
                          getCategorySectionLink(4) || `${routers.pressCenter}`
                        }
                      >
                        {getCategorySectionText(4)}
                      </MainLink>
                    </p>
                  </div>
                </CardInfo>
              </Link>
            </div>

            <Link
              className={styles.last}
              to={getPath(5) || `${routers.pressCenter}`}
              target='_blank'
            >
              <CardInfo title={getTitle(5)} text={getText(5)}>
                <div>
                  <div className={styles.image}>
                    <img
                      src={
                        actualNews[5]?.file?.url
                          ? `${BASE_URL}${actualNews[5]?.file?.url}`
                          : ImgTop
                      }
                      alt=''
                    />
                  </div>
                  <div className={styles.headCard}>
                    <p className={styles.date}>{getLeftTopContent(5)}</p>
                    <p
                      className={`${styles.link}`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <MainLink
                        target='_self'
                        path={
                          getCategorySectionLink(5) || `${routers.pressCenter}`
                        }
                      >
                        {getCategorySectionText(5)}
                      </MainLink>
                    </p>
                  </div>
                </div>
              </CardInfo>
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};
export default Actual;
