import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import routers from 'src/navigation/routers';
import CardsReview from 'src/pages/analytics/review/CardsReview';
import { getLawsuitThunk } from 'src/redux/news/thunks';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { KeysAssetNewsEnum, TCommentItem, TEventItem } from 'src/types/news';
import { TPublicationItem } from 'src/types/publication';
import { TReview } from 'src/types/review';
import PublicationAndBooks from '../analytics/publicationAndBooks/PublicationAndBooks';
import Comments from '../pressCenter/comments/Comments';
import Events from '../pressCenter/events/Events';
import News from '../pressCenter/news/News';
import Button from '../ui/shared/button/Button';
import Container from '../ui/shared/container/Container';
import LinkWithIcon from '../ui/shared/linkWithIcon/LinkWithIcon';
import Loader from '../ui/shared/loader/Loader';
import Tabs from '../ui/shared/tabs/Tabs';
import TitleSection from '../ui/shared/titleSection/TitleSection';
import styles from './lawsuit.module.scss';
import InformationSection from './informationSection/InformationSection';

type TItemOptions = { id: number | string; name: string };
type TAssetsContent =
  | TPublicationItem[]
  | TEventItem[]
  | TCommentItem[]
  | TReview[];

const KEY_TAB_SORT = [
  'News',
  'Event',
  'MediaComment',
  'Publication',
  'Book',
  'Review',
];

const Lawsuit = () => {
  const { t, i18n } = useTranslation('common');

  const [assetOptions, setAssetOptions] = useState<TItemOptions[]>();
  const [contentTab, setContentTab] = useState<TAssetsContent>([]);
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState<string>('all');

  const dispatch = useAppDispatch();
  const { newsLawsuit, newsLawsuitLoading } = useAppSelector((state) => ({
    newsLawsuit: state.news.newsLawsuit,
    newsLawsuitLoading: state.news.newsLawsuitLoading,
  }));

  const changeTab = (itemTab: number, item: TItemOptions) => {
    setTab(itemTab);
    switch (item.id) {
      case KeysAssetNewsEnum.Book:
        setTabName(item.id);
        return setContentTab(newsLawsuit.Book);
      case KeysAssetNewsEnum.Event:
        setTabName(item.id);
        return setContentTab(newsLawsuit.Event);
      case KeysAssetNewsEnum.MediaComment:
        setTabName(item.id);
        return setContentTab(newsLawsuit.MediaComment);
      case KeysAssetNewsEnum.News:
        setTabName(item.id);
        return setContentTab(newsLawsuit.News);
      case KeysAssetNewsEnum.Publication:
        setTabName(item.id);
        return setContentTab(newsLawsuit.Publication);
      case KeysAssetNewsEnum.Review:
        setTabName(item.id);
        return setContentTab(newsLawsuit.Review);
      default:
        setTabName('all');
        return setContentTab([]);
    }
  };

  useEffect(() => {
    dispatch(getLawsuitThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const getLocaleTitle = (title: string) => {
    switch (title) {
      case KeysAssetNewsEnum.Book:
        return t('books');
      case KeysAssetNewsEnum.Event:
        return t('events');
      case KeysAssetNewsEnum.MediaComment:
        return t('mediaComments');
      case KeysAssetNewsEnum.News:
        return t('news');
      case KeysAssetNewsEnum.Publication:
        return t('publication');
      case KeysAssetNewsEnum.Review:
        return t('bankruptcyTitle');
      default:
        return title;
    }
  };

  useEffect(() => {
    if (newsLawsuit !== null) {
      const keysValueAssets = Object.entries(newsLawsuit);

      const tab = keysValueAssets.filter(
        (item) => item[1].length !== 0 && item[0],
      );

      const options = tab.map((item) => {
        return { id: item[0], name: getLocaleTitle(item[0]) };
      });

      const optionsSorted = [];
      for (let i = 0; i < KEY_TAB_SORT.length; i++) {
        const foundItem = options.find((item) => item.id === KEY_TAB_SORT[i]);
        if (foundItem) {
          optionsSorted.push(foundItem);
        }
      }

      setAssetOptions([{ id: 'all', name: t('seeAll') }, ...optionsSorted]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsLawsuit]);

  function isComment(item: TAssetsContent): item is TCommentItem[] {
    return (item as TCommentItem[])[0].media_comment !== undefined;
  }

  function isEvent(item: TAssetsContent): item is TEventItem[] {
    return (item as TEventItem[])[0].is_event !== undefined;
  }

  function isPublication(item: TAssetsContent): item is TPublicationItem[] {
    return (item as TPublicationItem[])[0].description !== undefined;
  }

  function isReview(item: TAssetsContent): item is TReview[] {
    return (item as TReview[])[0].category !== undefined;
  }

  const renderContent = () => {
    if (tabName !== 'all') {
      if (contentTab.length > 0) {
        switch (tabName) {
          case KeysAssetNewsEnum.Book:
            if (isPublication(contentTab)) {
              return (
                <PublicationAndBooks
                  title={`${t('books')}`}
                  cards={contentTab}
                  textCardFooter={`${t('books')} | ${t(
                    'navigationLinks.newsLawsuit',
                  )}`}
                  link={`${routers.analytics}/lawsuit-against-belarus/books`}
                />
              );
            }
            break;
          case KeysAssetNewsEnum.Event:
            if (isEvent(contentTab)) {
              return (
                <Events
                  textCardFooter={`${t('events')} | ${t(
                    'navigationLinks.newsLawsuit',
                  )}`}
                  events={contentTab}
                  linkSeeAll={`${routers.pressCenter}/lawsuit-against-belarus/events`}
                />
              );
            }
            break;
          case KeysAssetNewsEnum.MediaComment:
            if (isComment(contentTab)) {
              return (
                <Comments
                  textCardFooter={`${t('mediaComments')} | ${t(
                    'navigationLinks.newsLawsuit',
                  )}`}
                  comments={contentTab}
                  linkSeeAll={`${routers.pressCenter}/lawsuit-against-belarus/comments`}
                />
              );
            }
            break;
          case KeysAssetNewsEnum.News:
            if (isEvent(contentTab)) {
              return (
                <News
                  textCardFooter={`${t('news')} | ${t(
                    'navigationLinks.newsLawsuit',
                  )}`}
                  news={contentTab}
                  linkSeeAll={`${routers.pressCenter}/lawsuit-against-belarus/news`}
                />
              );
            }
            break;
          case KeysAssetNewsEnum.Publication:
            if (isPublication(contentTab)) {
              return (
                <PublicationAndBooks
                  title={`${t('publication')}`}
                  cards={contentTab}
                  textCardFooter={`${t('publication')} | ${t(
                    'navigationLinks.newsLawsuit',
                  )}`}
                  link={`${routers.analytics}/lawsuit-against-belarus/publication`}
                />
              );
            }
            break;
          case KeysAssetNewsEnum.Review:
            if (isReview(contentTab)) {
              return (
                <>
                  <TitleSection
                    className={styles.titleReview}
                    title={`${t('bankruptcyTitle')}`}
                  />
                  <CardsReview
                    textCardFooter={`${t('navigationLinks.newsLawsuit')}`}
                    cardsReview={contentTab}
                  />
                  <div className={styles.showAllLink}>
                    <LinkWithIcon
                      path={`${routers.analytics}/review/all/lawsuit-against-belarus`}
                      text={t('seeAll')}
                      target='_parent'
                    />
                  </div>
                  <div className={styles.buttonAll}>
                    <Link
                      to={`${routers.analytics}/review/all/lawsuit-against-belarus`}
                    >
                      <Button icon>{t('seeAll')}</Button>
                    </Link>
                  </div>
                </>
              );
            }
            break;
          default:
            return null;
        }
      }
    } else {
      return (
        <div>
          {newsLawsuit.News.length > 0 && (
            <News
              textCardFooter={`${t('news')} | ${t(
                'navigationLinks.newsLawsuit',
              )}`}
              news={newsLawsuit.News}
              linkSeeAll={`${routers.pressCenter}/lawsuit-against-belarus/news`}
            />
          )}
          {newsLawsuit.Event.length > 0 && (
            <Events
              textCardFooter={`${t('events')} | ${t(
                'navigationLinks.newsLawsuit',
              )}`}
              events={newsLawsuit.Event}
              linkSeeAll={`${routers.pressCenter}/lawsuit-against-belarus/events`}
            />
          )}
          {newsLawsuit.MediaComment.length > 0 && (
            <Comments
              textCardFooter={`${t('mediaComments')} | ${t(
                'navigationLinks.newsLawsuit',
              )}`}
              comments={newsLawsuit.MediaComment}
              linkSeeAll={`${routers.pressCenter}/lawsuit-against-belarus/comments`}
            />
          )}
          {newsLawsuit.Publication.length > 0 && (
            <PublicationAndBooks
              title={`${t('publication')}`}
              cards={newsLawsuit.Publication}
              textCardFooter={`${t('publication')} | ${t(
                'navigationLinks.newsLawsuit',
              )}`}
              link={`${routers.analytics}/lawsuit-against-belarus/publication`}
            />
          )}
          {newsLawsuit.Book.length > 0 && (
            <PublicationAndBooks
              title={`${t('books')}`}
              cards={newsLawsuit.Book}
              textCardFooter={`${t('books')} | ${t(
                'navigationLinks.newsLawsuit',
              )}`}
              link={`${routers.analytics}/lawsuit-against-belarus/books`}
            />
          )}
          {newsLawsuit.Review.length > 0 && (
            <div>
              <TitleSection
                className={styles.titleReview}
                title={`${t('bankruptcyTitle')}`}
              />
              <CardsReview
                textCardFooter={`${t('navigationLinks.newsLawsuit')}`}
                cardsReview={newsLawsuit.Review}
                isPreview
              />
              <div className={styles.showAllLink}>
                <LinkWithIcon
                  path={`${routers.analytics}/review/all/lawsuit-against-belarus`}
                  text={t('seeAll')}
                  target='_parent'
                />
              </div>
              <div className={styles.buttonAll}>
                <Link
                  to={`${routers.analytics}/review/all/lawsuit-against-belarus`}
                >
                  <Button icon>{t('seeAll')}</Button>
                </Link>
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div className={styles.root}>
      <InformationSection />
      <Container isPaddingTop={false}>
        <div className={styles.assetTabsBlock}>
          {assetOptions && (
            <Tabs
              options={assetOptions}
              selectedItem={tab ?? ''}
              onChange={changeTab}
            />
          )}
        </div>
        <div className={styles.content}>
          {newsLawsuitLoading ? <Loader /> : renderContent()}
        </div>
      </Container>
    </div>
  );
};
export default Lawsuit;
