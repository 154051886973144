import { ReactNode } from 'react';
import { ReactComponent as Close } from '../../../../assets/images/icons/cross.svg';
import styles from './modal.module.scss';

interface Props {
  active: boolean;
  setActive: (isActive: boolean) => void;
  children: ReactNode;
  isCross?: boolean;
}

const Modal = ({
  active = false,
  setActive,
  children,
  isCross = true,
}: Props) => {
  const classModal = active
    ? `${styles.modal} ${styles.active}`
    : `${styles.modal}`;

  return (
    <div className={classModal} onClick={() => setActive(false)}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        {isCross && (
          <div className={styles.modalClose} onClick={() => setActive(false)}>
            <Close className={styles.icon} />
          </div>
        )}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
