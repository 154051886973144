import styles from './tabs.module.scss';

type Item = { id: number | string; name: string };

interface Props {
  options: Item[];
  onChange: (value: number, navitem: Item) => void;
  selectedItem: number;
}

const Tabs = ({ options, onChange, selectedItem }: Props) => (
  <nav className={styles.tabsNav}>
    {options?.map((navitem, index) => (
      <button
        key={navitem.id}
        onClick={() => onChange(index, navitem)}
        type='button'
        className={
          selectedItem === index
            ? `${styles.tab} ${styles.tabActive}`
            : styles.tab
        }
      >
        {navitem.name}
      </button>
    ))}
  </nav>
);

export default Tabs;
