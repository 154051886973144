import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendAskForm } from 'src/api/formAsk';
import { TAskForm } from 'src/types/shared';

export const sendAskFormThunk = createAsyncThunk(
  'sendAskForm',
  async (params: TAskForm) => {
    try {
      const { data: sendAsk } = await sendAskForm(params);
      return sendAsk;
    } catch (e) {
      throw new Error();
    }
  },
);
