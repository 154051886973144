import { Get } from 'src/axios';
import {
  TEvent,
  TComment,
  TEventItem,
  TCommentItem,
  TDataRequest,
  TDataRequestComment,
  TActualNews,
} from 'src/types/news';

const getEvents = ({
  is_event = 0,
  page,
  end_date,
  start_date,
  typeActives = 'event',
}: TDataRequest) =>
  Get<TEvent>(`/news/${typeActives}/`, {
    params: { is_event, page, end_date, start_date },
  });

const getEventsCurrent = (isEvent: 0 | 1 = 0, id: string, page = 1) =>
  Get<TEventItem>(`/news/event/${id}/`, {
    params: { is_event: isEvent, page },
  });

const getComments = ({
  page,
  end_date,
  start_date,
  typeActives = 'media_comment',
}: TDataRequestComment) =>
  Get<TComment>(`/news/${typeActives}/`, {
    params: { page, start_date, end_date },
  });

const getCommentCurrent = (id: string) =>
  Get<TCommentItem>(`/news/media_comment/${id}/`);

const getActualNews = (lang: string) =>
  Get<TActualNews>(`/news/main_news/?language=${lang}`);

const getActives = () => Get<any>('/news/actives/');

const getLawsuit = () => Get<any>('/news/lawsuit/');

export {
  getEvents,
  getComments,
  getEventsCurrent,
  getCommentCurrent,
  getActualNews,
  getActives,
  getLawsuit,
};
