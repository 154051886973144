import ImageContainerMain from 'src/components/ui/shared/entities/imageContainerMain/ImageContainerMain';
import Title from 'src/components/ui/shared/title/Title';
import Main from 'src/assets/images/company/main.png';
import styles from './sectionImage.module.scss';

interface IProps {
  image: string;
  title: string;
  description: string;
}

const SectionImage = ({ image, title, description }: IProps) => {
  return (
    <section className={styles.company}>
      <ImageContainerMain alt='About us' src={image ?? Main}>
        <div className={styles.companyText}>
          <Title title={title} />
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </ImageContainerMain>
    </section>
  );
};
export default SectionImage;
