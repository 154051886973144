import { BASE_URL } from 'src/axios/baseUrl';
import { useAppSelector } from 'src/redux/store';
import SliderRecommended from '../../ui/widgets/sliderRecommended/SliderRecommended';
import styles from './recommended.module.scss';

const Recommended = () => {
  const { rating, ratingImageHomeInfo } = useAppSelector((state) => ({
    rating: state.rating.ratingHomeInfo,
    ratingImageHomeInfo: state.staticPages.ratingImageHomeInfo,
  }));

  return (
    <section className={styles.sliderHome}>
      <div>
        {rating && <SliderRecommended rating={rating} />}
        <div className={styles.recommend}>
          {ratingImageHomeInfo?.map((item) => (
            <div className={styles.recommendItem} key={item?.name}>
              <img src={`${BASE_URL}${item?.image?.url}` || ''} alt='' />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default Recommended;
