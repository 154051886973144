import { ReactNode } from 'react';
import styles from './imageContainerMain.module.scss';

interface Props {
  src: string;
  alt: string;
  children?: ReactNode;
  isCenter?: boolean;
  color?: string;
}

const ImageContainerMain = ({
  src,
  alt,
  children,
  isCenter = true,
  color,
}: Props) => {
  return (
    <div className={styles.imageContainer} style={{ background: color }}>
      {src !== null && <img className={styles.image} src={src} alt={alt} />}
      <div
        className={
          isCenter ? styles.text : `${styles.text} ${styles.textNoCenter}`
        }
      >
        {children}
      </div>
    </div>
  );
};

export default ImageContainerMain;
