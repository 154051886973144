/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Company from 'src/pages/company/Company';
import Home from 'src/pages/Home';
import Employee from 'src/pages/team/Employee';
import Team from 'src/pages/team/Team';
import PressCenter from 'src/pages/PressCenter';
import CurrentNews from 'src/pages/CurrentNews';
import Layout from '../components/ui/layouts/Layout';
import routers from './routers';
import Services from 'src/pages/services/Services';
import Service from 'src/pages/services/Service';
import CurrentServicePage from 'src/pages/services/CurrentServicePage';
import Bounds from 'src/pages/bounds/Bonds';
import EventsAllPage from 'src/pages/EventsAllPage';
import Contact from 'src/pages/contact/Contact';
import { useAppDispatch } from 'src/redux/store';
import {
  getCommentsThunk,
  getEventsThunk,
  getNewsThunk,
} from 'src/redux/news/thunks';
import { useTranslation } from 'react-i18next';
import Analytics from 'src/pages/analytics/Analytics';
import PublicationsAllPage from 'src/pages/analytics/publication/PublicationsAllPage';
import ReviewsAllPage from 'src/pages/analytics/review/ReviewAllPage';
import CurrentPublication from 'src/pages/analytics/publication/CurrentPublication';
import CurrentReviews from 'src/pages/analytics/review/CurrentReview';
import AssetsUnlocking from 'src/pages/AssetsUnlockingPage';
import Lawsuit from 'src/pages/LawsuitPage';
import ErrorPage from 'src/pages/errorPage';
import { ProtectedRoute } from './ProtectedRoute';
import { Helmet } from 'react-helmet-async';

const Career = lazy(() => import('src/pages/career/Career'));

const AppRouter = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation('common');
  const location = useLocation();

  const pathNews = [
    routers.services,
    routers.pressCenter,
    routers.informationBonds,
  ];

  useEffect(() => {
    if (pathNews.includes(location.pathname)) {
      dispatch(getNewsThunk({ is_event: 0, page: 1 }));
      dispatch(getEventsThunk({ is_event: 1, page: 1 }));
      dispatch(getCommentsThunk({ page: 1 }));
    }
  }, [dispatch, i18n.language, location.pathname]);

  return (
    <>
      <Helmet>
        <meta name='description' content={t('meta.description.common') ?? ''} />
      </Helmet>
      <Layout>
        <Suspense>
          <Routes>
            <Route path={routers.home} element={<Home />} />
            <Route path={routers.team} element={<Team />} />
            <Route path={`${routers.team}/:id`} element={<Employee />} />
            <Route path={routers.company} element={<Company />} />
            <Route path={routers.pressCenter} element={<PressCenter />} />
            <Route
              path={`${routers.pressCenter}/:type/:id`}
              element={<CurrentNews />}
            />
            <Route path={routers.services} element={<Services />} />
            <Route
              path={`${routers.services}/:idService`}
              element={<Service />}
            />
            <Route
              path={`${routers.services}/:idService/:currentIdService`}
              element={<CurrentServicePage />}
            />
            <Route path={routers.informationBonds} element={<Bounds />} />
            <Route
              path={`${routers.pressCenter}/:type`}
              element={<EventsAllPage />}
            />
            <Route path={routers.contact} element={<Contact />} />
            <Route
              path={`${routers.analytics}/:type`}
              element={<PublicationsAllPage />}
            />
            <Route
              path={`${routers.analytics}/:unlocking/:type`}
              element={<PublicationsAllPage />}
            />
            <Route
              path={`${routers.pressCenter}/unlocking/:type`}
              element={<EventsAllPage />}
            />
            <Route
              path={`${routers.analytics}/:lawsuit/:type`}
              element={<PublicationsAllPage />}
            />
            <Route
              path={`${routers.pressCenter}/lawsuit-against-belarus/:type`}
              element={<EventsAllPage />}
            />
            <Route
              path={`${routers.analytics}/publication/:id`}
              element={<CurrentPublication />}
            />
            <Route
              path={`${routers.analytics}/review/:id`}
              element={<CurrentReviews />}
            />
            <Route
              path={`${routers.analytics}/review/all/:id`}
              element={<ReviewsAllPage />}
            />
            <Route
              path={`${routers.analytics}/review/all/unlocking`}
              element={<ReviewsAllPage />}
            />
            <Route path={routers.analytics} element={<Analytics />} />
            <Route
              path={routers.assetsUnlocking}
              element={
                <ProtectedRoute
                  isAllowed={i18n.language === 'ru'}
                  redirectPath={routers.home}
                >
                  <AssetsUnlocking />
                </ProtectedRoute>
              }
            />
            <Route
              path={routers.lawsuit}
              element={
                <ProtectedRoute
                  isAllowed={i18n.language === 'ru'}
                  redirectPath={routers.home}
                >
                  <Lawsuit />
                </ProtectedRoute>
              }
            />
            <Route path={routers.career} element={<Career />} />
            {/* 301 redirect */}
            <Route
              path={routers.groupClaimOld}
              element={<Navigate replace to={routers.groupClaimNew} />}
            />
            <Route
              path={routers.groupClaimOldSecondary}
              element={<Navigate replace to={routers.groupClaimNew} />}
            />
            <Route path='*' element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </Layout>
    </>
  );
};

export default AppRouter;
