import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DragAndDrop from 'src/components/ui/shared/dragDrop/DragAndDrop';
import Input from 'src/components/ui/shared/input/Input';
import Loader from 'src/components/ui/shared/loader/Loader';
import { sendResumeFormThunk } from 'src/redux/resume/thunk';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { ReactComponent as Plus } from '../../../../assets/images/icons/plus.svg';
import Button from '../../../ui/shared/button/Button';
import Modal from '../../../ui/shared/modal/Modal';
import TitleSection from '../../../ui/shared/titleSection/TitleSection';
import styles from './cardAdd.module.scss';

type validForm = {
  name: string | null;
  file: File | null | '';
};

const CardAdd = () => {
  const { t } = useTranslation(['team', 'modal']);
  const [modalActive, setModalActive] = useState(false);
  const [modalSendStatusActive, setModalSendStatusActive] = useState(false);
  const [valueName, setValueName] = useState<string | null>();
  const [validForm, setValidForm] = useState<validForm>({
    name: null,
    file: null,
  });
  const [myFile, setFile] = useState<File | null>();
  const dispatch = useAppDispatch();

  const { isLoad, isError } = useAppSelector((state) => ({
    isLoad: state.resume.loading,
    isError: state.resume.error,
  }));

  useEffect(() => {
    if (validForm.name !== null || validForm.file !== null)
      setValidForm({ name: valueName || '', file: myFile || '' });
  }, [myFile, validForm.file, validForm.name, valueName]);

  const onSubmitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (valueName && myFile !== undefined && myFile !== null) {
      setValidForm({ name: valueName, file: myFile });
      dispatch(sendResumeFormThunk({ name: valueName, file: myFile }));
      setModalActive(false);
      setModalSendStatusActive(true);
    } else {
      setValidForm({ name: valueName || '', file: myFile || '' });
    }
  };

  const onCloseResultSend = () => {
    setValueName(null);
    setFile(null);
    setValidForm({ name: null, file: null });
    setModalSendStatusActive(false);
  };

  return (
    <>
      <a href='mailto:job@nsplaw.com' className={styles.cardBlock}>
        <div className={styles.card}>
          <Plus />
        </div>
      </a>
      <p className={styles.text}>{t('team:addTeam')}</p>
      <Modal active={modalActive} setActive={setModalActive}>
        <div className={styles.modalForm}>
          <TitleSection title={t('modal:partTeam')} />
          <p className={styles.textModal}>{t('modal:sendResume')}</p>
          <form onSubmit={onSubmitForm}>
            <div className={styles.inputName}>
              <Input
                name='name'
                placeholder={`${t('modal:name')}`}
                isValid={
                  validForm?.name !== null &&
                  validForm?.name.trim().length === 0
                    ? false
                    : true
                }
                value={valueName || ''}
                onChange={(value) => setValueName(value.toString())}
              />
            </div>
            <DragAndDrop
              setFile={setFile}
              file={myFile}
              isValid={validForm?.file === '' ? false : true}
            />
            <Button
              type='submit'
              disabled={valueName?.trim().length === 0 ? true : false}
            >
              {t('modal:sendResumeButton')}
            </Button>
          </form>
        </div>
      </Modal>
      <Modal active={modalSendStatusActive} setActive={onCloseResultSend}>
        {isLoad ? (
          <Loader />
        ) : (
          <>
            {isError ? (
              <div className={styles.textError}>
                <TitleSection title={t('modal:error')} />{' '}
              </div>
            ) : (
              <div className={styles.titleBlock}>
                <TitleSection title={t('modal:requesResumeSend')} />
              </div>
            )}
          </>
        )}
      </Modal>
    </>
  );
};
export default CardAdd;
