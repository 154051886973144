/* eslint-disable react-hooks/exhaustive-deps */
import NewsContent from './shared/newsContent/NewsContent';
import styles from './currentNews.module.scss';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import routers from 'src/navigation/routers';
import NewsBlock from '../ui/widgets/newsBlock/NewsBlock';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  getCommentCurrentThunk,
  getEventCurrentThunk,
  getNewsCurrentThunk,
} from 'src/redux/news/thunks';
import Loader from '../ui/shared/loader/Loader';

const CurrentNews = () => {
  const { t, i18n } = useTranslation('common');
  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (params.id) {
      switch (params.type) {
        case 'news':
          dispatch(getNewsCurrentThunk(params.id)).then((payload) => {
            const isNotFounded = payload.meta.requestStatus === 'rejected';

            if (isNotFounded) navigate(`/press-center/news`);
          });
          break;
        case 'events':
          dispatch(getEventCurrentThunk(params.id));
          break;
        case 'comments':
          dispatch(getCommentCurrentThunk(params.id));
          break;
      }
    }
  }, [params.id, i18n.language]);

  const { loadingCurrent } = useAppSelector((state) => ({
    loadingCurrent: state.news.loadingCurrent,
  }));

  const currentNews = useAppSelector((state) => {
    switch (params.type) {
      case 'news':
        return state.news.newsCurrent;
      case 'comments':
        return state.news.commentCurrent;
      case 'events':
        return state.news.eventsCurrent;
    }
  });

  const getBreadcrumbPart = () => {
    switch (params.type) {
      case 'news':
        return t('newsEvent');
      case 'comments':
        return t('mediaComments');
      case 'events':
        return t('events');
    }
  };

  return (
    <section>
      <p className={styles.breadcrumbs}>
        <NavLink to={routers.pressCenter}>
          {t('pressCenter').toUpperCase()}
        </NavLink>{' '}
        / {getBreadcrumbPart()}
      </p>
      {currentNews && (
        <div className={styles.contentWrapper}>
          {loadingCurrent ? (
            <Loader />
          ) : (
            <NewsContent currentNews={currentNews} />
          )}
          {currentNews.related_events?.length > 0 && (
            <NewsBlock news={currentNews.related_events} />
          )}
        </div>
      )}
    </section>
  );
};

export default CurrentNews;
