import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  getActives,
  getActualNews,
  getCommentCurrent,
  getComments,
  getEvents,
  getEventsCurrent,
  getLawsuit,
} from 'src/api/news';
import { TDataRequest, TDataRequestComment } from 'src/types/news';

export const getNewsThunk = createAsyncThunk(
  'getNews',
  async (params: TDataRequest) => {
    try {
      const { data: news } = await getEvents(params);
      return news;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getEventsThunk = createAsyncThunk(
  'getEvents',
  async (params: TDataRequest) => {
    try {
      const { data: events } = await getEvents(params);
      return events;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getCommentsThunk = createAsyncThunk(
  'getComments',
  async (params: TDataRequestComment) => {
    try {
      const { data: comments } = await getComments(params);
      return comments;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getNewsCurrentThunk = createAsyncThunk(
  'getNewsCurrent',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data: newsCurrent } = await getEventsCurrent(0, id);
      return newsCurrent;
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 404) {
          throw rejectWithValue('Item not found');
        }
      }

      console.error(e);
    }
  },
);
export const getEventCurrentThunk = createAsyncThunk(
  'getEventCurrent',
  async (id: string) => {
    try {
      const { data: eventsCurrent } = await getEventsCurrent(1, id);
      return eventsCurrent;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getCommentCurrentThunk = createAsyncThunk(
  'getCommentCurrent',
  async (id: string) => {
    try {
      const { data: commentCurrent } = await getCommentCurrent(id);
      return commentCurrent;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getActualNewsThunk = createAsyncThunk(
  'getActualNews',
  async (lang: string) => {
    try {
      const { data: actualNews } = await getActualNews(lang);
      return actualNews;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getActivesThunk = createAsyncThunk('getActives', async () => {
  try {
    const { data: newsActives } = await getActives();
    return newsActives;
  } catch (e) {
    console.log(e);
  }
});

export const getLawsuitThunk = createAsyncThunk('getLawsuit', async () => {
  try {
    const { data: newsLawsuit } = await getLawsuit();
    return newsLawsuit;
  } catch (e) {
    console.log(e);
  }
});
