import { IframeHTMLAttributes } from 'react';
import styles from './map.module.scss';

const Map = ({ src }: Pick<IframeHTMLAttributes<HTMLIFrameElement>, 'src'>) => (
  <div className={styles.map}>
    <iframe
      src={src}
      width='100%'
      height='640px'
      style={{ border: 0 }}
      allowFullScreen={false}
    ></iframe>
  </div>
);
export default Map;
