import styles from './allCardContainer.module.scss';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const AllCardContainer = ({ children }: Props) => {
  return <ul className={styles.contentList}>{children}</ul>;
};
export default AllCardContainer;
