import { Trans, useTranslation } from 'react-i18next';
import TitleSection from 'src/components/ui/shared/titleSection/TitleSection';
import { TAboutMembers } from 'src/types/aboutCompany';
import styles from './experience.module.scss';

interface IProps {
  members: TAboutMembers[];
}

const Experience = ({ members }: IProps) => {
  const { t } = useTranslation(['company']);

  const DATA = [
    {
      id: 0,
      text: t('experienceDescOne'),
      title: (
        <Trans
          t={t}
          defaults={`${t('experienceTitleOne')}`}
          values={{ value: '10+' }}
        />
      ),
    },
    {
      id: 1,
      text: t('experienceDescTwo'),
      title: (
        <Trans
          t={t}
          defaults={`${t('experienceTitleOne')}`}
          values={{ value: '20+' }}
        />
      ),
    },
    {
      id: 2,
      text: t('experienceDescThree'),
      title: (
        <Trans
          t={t}
          defaults={`${t('experienceTitleOne')}`}
          values={{ value: '900+' }}
        />
      ),
    },
    {
      id: 3,
      text: t('experienceDescFour'),
      title: (
        <Trans
          t={t}
          defaults={`${t('experienceTitleTwo')}`}
          values={{ value: '8+' }}
        />
      ),
    },
    {
      id: 4,
      text: t('experienceDescFive'),
      title: (
        <Trans
          t={t}
          defaults={`${t('experienceTitleThree')}`}
          values={{ value: '30+' }}
        />
      ),
    },
    {
      id: 5,
      text: t('experienceDescSix'),
      title: (
        <Trans
          t={t}
          defaults={`${t('experienceTitleFour')}`}
          values={{ value: '5+' }}
        />
      ),
    },
  ];
  return (
    <section className={styles.experienceSection}>
      <TitleSection title={t('experience')} />
      <div className={styles.experienceBody}>
        {(members.length > 0 ? members : DATA)?.map((item, index) => (
          <div key={index} className={styles.card}>
            <p className={styles.title}>{item.title}</p>
            <div className={styles.border} />
            <p className={styles.text}>{item.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
export default Experience;
