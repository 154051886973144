import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TContact } from 'src/types/contacts';
import { getContactsThunk } from './thunk';

const initialState: {
  contacts: TContact[];
  ruContacts: TContact | null;
} = {
  contacts: [],
  ruContacts: null,
};

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setRuContacts: (state, action: PayloadAction<TContact>) => {
      state.ruContacts = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getContactsThunk.fulfilled, (state, action) => {
      state.contacts = action.payload || [];
    });
  },
});
export const { setRuContacts } = contactsSlice.actions;
export default contactsSlice.reducer;
