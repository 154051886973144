import { useTranslation } from 'react-i18next';
import styles from './errorPage.module.scss';
import { Helmet } from 'react-helmet-async';
import React from 'react';

const ErrorPage = () => {
  const { t } = useTranslation('error');
  const { t: c } = useTranslation('common');

  return (
    <div className={styles.error}>
      <Helmet>
        <title>{c('pageTitles.home')}</title>
      </Helmet>
      <h1>{t('error')}</h1>
      <p>404</p>
    </div>
  );
};

export default ErrorPage;
