import { useTranslation } from 'react-i18next';
import MainLink from '../../../shared/link/MainLink';
import styles from './footerBottom.module.scss';
import PersonalData from 'src/assets/politicsDocument/legal.pdf';

const FooterBottom = () => {
  const { t, i18n } = useTranslation(['common']);

  const LINKSPOLITIC = [
    {
      title: t('linkPolitic.personalData'),
      path: PersonalData,
    },
    // {
    //   title: t('linkPolitic.useCookies'),
    //   path: '/useCookies',
    // },
    // {
    //   title: t('linkPolitic.confirm'),
    //   path: '/confirm',
    // },
  ];

  return (
    <div className={styles.bottomBlock}>
      <div className={styles.copyrightBlock}>
        <p>© Nektorov, Saveliev & Partners (since 2006)</p>
        {i18n.language === 'ru' && (
          <p>
            Официальный сайт Адвокатского бюро Некторов, Савельев и Партнеры (г.
            Москва) (Адвокатская палата г. Москвы, реестровый номер 77/2-337)
          </p>
        )}
      </div>
      <div className={styles.footerLinkBottom}>
        {LINKSPOLITIC?.map((item) => (
          <div key={item.path} className={styles.footerLinkTitle}>
            <MainLink path={item.path}>
              <span>{item.title}</span>
            </MainLink>
          </div>
        ))}
      </div>
    </div>
  );
};
export default FooterBottom;
