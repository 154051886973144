import { CSSProperties, ReactNode } from 'react';
import { ReactComponent as Arrow } from '../../../../assets/images/icons/arrowGo.svg';
import styles from './button.module.scss';

interface Props {
  type?: 'button' | 'submit';
  disabled?: boolean;
  onClick?: () => void;
  children: ReactNode;
  icon?: boolean;
  className?: string;
  style?: CSSProperties;
}

const Button = ({
  type = 'button',
  disabled = false,
  onClick,
  children,
  icon = false,
  className,
  style,
}: Props) => (
  <button
    className={`${styles.button} ${className}`}
    style={style}
    type={type === 'submit' ? 'submit' : 'button'}
    onClick={onClick}
    disabled={disabled}
  >
    <span>{children}</span>
    {icon && <Arrow className={styles.icon} />}
  </button>
);

export default Button;
