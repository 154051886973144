import MainText from '../../../assets/images/home/Wedomore.png';
import Main from '../../../assets/images/home/main.webp';
import ImageContainerMain from '../../ui/shared/entities/imageContainerMain/ImageContainerMain';
import styles from './sectionImage.module.scss';
import { useAppSelector } from 'src/redux/store';
import { useState, useEffect } from 'react';
import { BASE_URL } from 'src/axios/baseUrl';
import Button from 'src/components/ui/shared/button/Button';
import { useNavigate } from 'react-router-dom';
import Slider from 'src/components/ui/shared/slider/Slider';
import { SwiperSlide } from 'swiper/react';
import { TBannerHome } from 'src/types/staticpages';
import { useTranslation } from 'react-i18next';

const bannerMain = [
  {
    alt: 'we do more',
    about_text: '',
    button: '',
    color: '',
    color_button: '',
    image: Main,
    is_active: false,
    name: '',
    url: '',
  },
];

const SectionImage = () => {
  const { i18n } = useTranslation('common');

  const [banner, setBanner] = useState<TBannerHome[]>(bannerMain);

  const { bannerData } = useAppSelector((state) => ({
    bannerData: state.staticPages.bannerHome,
  }));

  const navigate = useNavigate();

  useEffect(() => {
    const filterForLanguage = (banner: TBannerHome) => {
      if (i18n.language !== 'ru') {
        if (banner.name === 'Разблокировка активов частных инвесторов') {
          return false;
        } else if (
          banner.name ===
          'Объявление о сборе инвесторов для подачи иска против Республики Беларусь'
        ) {
          return false;
        }
      }

      return true;
    };

    setBanner(
      [...bannerMain, ...bannerData.filter(filterForLanguage)].reverse(),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerData]);

  const onBannerClick = (bannerInfo: TBannerHome) => {
    if (!bannerInfo?.url) return;

    const { url } = bannerInfo;

    // external link check
    try {
      // TODO: migrate to URL.canParse or URL.parse
      const { href } = new URL(url);

      window.open(href, '_blank', 'noopener,noreferrer');
    } catch {
      navigate(url);
    }
  };

  return (
    <div
      className={styles.homeImage}
      style={{ backgroundColor: banner[0].color || '#000' }}
    >
      <Slider autoplay loop isNavigation={false}>
        {banner?.map((item, index) => {
          const buttonStyles = item.color_button
            ? { backgroundColor: item.color_button }
            : {};

          return (
            <SwiperSlide key={index} onClick={() => onBannerClick(item)}>
              <ImageContainerMain
                isCenter={false}
                alt='banner'
                src={
                  item.name !== ''
                    ? item.image !== null
                      ? `${BASE_URL}${item?.image?.url}`
                      : null
                    : Main
                }
                color={item.color}
              >
                {item.name === '' ? (
                  <div>
                    <img src={MainText} alt='we do more' />
                  </div>
                ) : !item.button ? null : (
                  <div className={styles.bannerBlock}>
                    <div
                      className={styles.bannerTitle}
                      dangerouslySetInnerHTML={{
                        __html: item?.name,
                      }}
                    />
                    <div
                      className={styles.bannerText}
                      dangerouslySetInnerHTML={{
                        __html: item?.about_text,
                      }}
                    />
                    <Button icon style={buttonStyles}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.button,
                        }}
                      />
                    </Button>
                  </div>
                )}
              </ImageContainerMain>
            </SwiperSlide>
          );
        })}
      </Slider>
    </div>
  );
};
export default SectionImage;
