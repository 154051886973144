import { useTranslation } from 'react-i18next';
import Button from '../../shared/button/Button';
import styles from './keyContacts.module.scss';
import { TEmployee } from 'src/types/shared';
import { BASE_URL } from 'src/axios/baseUrl';
import { Link } from 'react-router-dom';

interface IProps {
  contacts?: TEmployee[];
  type?: string;
}

const KeyContacts = ({ contacts, type }: IProps) => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.contactsBlock}>
      <h2 className={styles.title}>{t('keyContacts')}</h2>
      <div
        className={
          contacts?.length === 1
            ? styles.contactsCardsOne
            : styles.contactsCards
        }
      >
        {contacts?.map((item, index) => (
          <article className={styles.contacts} key={index}>
            <img
              src={
                type !== 'bounds'
                  ? `${BASE_URL}${item.photo?.url || ''}`
                  : item?.photo?.url
              }
            />
            <div className={styles.contactsEmployee}>
              <h3>{item.name}</h3>
              <p>{item.position}</p>
            </div>

            <div
              className={styles.contactsText}
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
            <Link to={`mailto:${item.email}`}>
              <Button className={styles.contactsButton}>
                {t('writeMessage')}
              </Button>
            </Link>
          </article>
        ))}
      </div>
    </div>
  );
};
export default KeyContacts;
