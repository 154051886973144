import { useTranslation } from 'react-i18next';
import TitleSection from '../../ui/shared/titleSection/TitleSection';
import NavigationLink from '../../ui/shared/navigate-link/NavigateLink';
import Links from '../../../navigation/links';
import { ReactComponent as Arrow } from '../../../assets/images/icons/arrowGo.svg';
import Button from '../../ui/shared/button/Button';
import CardVideo from './cardVideo/CardVideo';
import styles from './video.module.scss';
import { Link } from 'react-router-dom';

const Video = () => {
  const { t } = useTranslation(['home']);
  return (
    <section className={styles.video}>
      <div className={styles.linkYoutube}>
        <TitleSection title={t('section.video')} />
        <div className={styles.link}>
          <NavigationLink path={Links.video}>
            <div className={styles.linkGo}>
              <p>{t('goYoutube')}</p>
              <Arrow className={styles.arrow} />
            </div>
          </NavigationLink>
        </div>
      </div>
      <div className={styles.videoBody}>
        <div className={styles.videoBig}>
          <CardVideo
            link='https://www.youtube.com/embed/fEdGDEX6jjk'
            date='9 декабря 2020 г'
            text='Субсидиарная ответственность директоров и проверка происхождения капитала. Александр Некторов для ВТБ Private Banking'
          />
        </div>

        <div className={styles.videoPanel}>
          <div className={styles.videoMini}>
            <CardVideo
              mini
              link='https://www.youtube.com/embed/fEdGDEX6jjk'
              date='9 декабря 2020 г'
              text='Субсидиарная ответственность директоров и проверка происхождения капитала. Александр Некторов для ВТБ Private Banking'
            />
          </div>
          <div>
            <CardVideo
              mini
              link='https://www.youtube.com/embed/6HJQZCZdC5Q'
              date='15 марта 2017 г.'
              text='Может ли бенефициар оспорить решение собрания или сделку общества?'
            />
          </div>
          <div>
            <CardVideo
              mini
              link='https://www.youtube.com/embed/46GM_1527jQ'
              date='1 февраля 2017 г.'
              text='Какие виды пользовательских соглашений встречаются в сети?'
            />
          </div>
          <div>
            <CardVideo
              mini
              link='https://www.youtube.com/embed/SEoP_10JlI4'
              date='16 февраля 2017 г.'
              text='Как правильно заключить соглашение с пользователем?'
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonGo}>
        <Link to={Links.video} target='_blank'>
          <Button icon>{t('goYoutube')}</Button>
        </Link>
      </div>
    </section>
  );
};
export default Video;
