import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getBannerHome,
  getImageOrganizationHome,
  getVideo,
  setLanguage,
} from 'src/api/staticpages';

export const getImageRatingThunk = createAsyncThunk(
  'getImageRating',
  async () => {
    try {
      const { data: ratingImageHomeInfo } = await getImageOrganizationHome();
      return ratingImageHomeInfo;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getIsVideoThunk = createAsyncThunk('getIsVideo', async () => {
  try {
    const { data: isActiveVideo } = await getVideo();
    return isActiveVideo.is_active;
  } catch (e) {
    console.log(e);
  }
});

export const getBannerThunk = createAsyncThunk('getBanner', async () => {
  try {
    const { data: bannerHome } = await getBannerHome();
    return bannerHome;
  } catch (e) {
    console.log(e);
  }
});

export const setLaguageThunk = createAsyncThunk(
  'setLanguage',
  async (lang: string) => {
    try {
      await setLanguage(lang);
    } catch (e) {
      console.log(e);
    }
  },
);
