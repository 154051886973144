import { createSlice } from '@reduxjs/toolkit';
import {
  TActualNews,
  TAssetNews,
  TComment,
  TCommentItem,
  TEvent,
  TEventItem,
  TnewsLawsuit,
} from 'src/types/news';
import {
  getActivesThunk,
  getActualNewsThunk,
  getCommentCurrentThunk,
  getCommentsThunk,
  getEventCurrentThunk,
  getEventsThunk,
  getLawsuitThunk,
  getNewsCurrentThunk,
  getNewsThunk,
} from './thunks';

const initialState: {
  events: TEvent | null;
  comments: TComment | null;
  news: TEvent | null;
  loadingNews: boolean;
  loadingEvents: boolean;
  loadingComments: boolean;
  langDataNews: string;
  langDataEvents: string;
  langDataComment: string;
  eventsCurrent: TEventItem | null;
  newsCurrent: TEventItem | null;
  commentCurrent: TCommentItem | null;
  loadingCurrent: boolean;
  actualNews: TActualNews | null;
  newsActives: TAssetNews;
  newsActivesLoading: boolean;
  newsLawsuit: TnewsLawsuit;
  newsLawsuitLoading: boolean;
} = {
  events: null,
  comments: null,
  news: null,
  loadingNews: false,
  loadingEvents: false,
  loadingComments: false,
  langDataNews: '',
  langDataEvents: '',
  langDataComment: '',
  eventsCurrent: null,
  newsCurrent: null,
  commentCurrent: null,
  loadingCurrent: false,
  actualNews: null,
  newsActives: {
    Book: [],
    Event: [],
    MediaComment: [],
    News: [],
    Publication: [],
    Review: [],
  },
  newsActivesLoading: false,
  newsLawsuit: {
    Book: [],
    Event: [],
    MediaComment: [],
    News: [],
    Publication: [],
    Review: [],
  },
  newsLawsuitLoading: false,
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getNewsThunk.fulfilled, (state, action) => {
      state.loadingNews = false;
      state.news = action.payload || null;
      if (action.payload?.results.length === 0) {
        state.langDataNews = 'noData';
      }
    });

    builder.addCase(getNewsThunk.pending, (state) => {
      state.langDataNews = '';
      state.loadingNews = true;
    });

    builder.addCase(getEventsThunk.fulfilled, (state, action) => {
      state.loadingEvents = false;
      state.events = action.payload || null;
      if (action.payload?.results.length === 0) {
        state.langDataEvents = 'noData';
      }
    });

    builder.addCase(getEventsThunk.pending, (state) => {
      state.loadingEvents = true;
      state.langDataEvents = '';
    });

    builder.addCase(getCommentsThunk.fulfilled, (state, action) => {
      state.loadingComments = false;
      state.comments = action.payload || null;
      if (action.payload?.results.length === 0) {
        state.langDataComment = 'noData';
      }
    });

    builder.addCase(getCommentsThunk.pending, (state) => {
      state.loadingComments = true;
      state.langDataComment = '';
    });

    builder.addCase(getNewsCurrentThunk.fulfilled, (state, action) => {
      state.loadingCurrent = false;
      state.newsCurrent = action.payload || null;
    });

    builder.addCase(getNewsCurrentThunk.pending, (state) => {
      state.loadingCurrent = true;
    });

    builder.addCase(getEventCurrentThunk.fulfilled, (state, action) => {
      state.loadingCurrent = false;
      state.eventsCurrent = action.payload || null;
    });

    builder.addCase(getEventCurrentThunk.pending, (state) => {
      state.loadingCurrent = true;
    });

    builder.addCase(getCommentCurrentThunk.fulfilled, (state, action) => {
      state.loadingCurrent = false;
      state.commentCurrent = action.payload || null;
    });

    builder.addCase(getCommentCurrentThunk.pending, (state) => {
      state.loadingCurrent = true;
    });

    builder.addCase(getActualNewsThunk.fulfilled, (state, action) => {
      state.actualNews = action.payload || null;
    });

    builder.addCase(getActivesThunk.pending, (state) => {
      state.newsActivesLoading = true;
    });

    builder.addCase(getActivesThunk.fulfilled, (state, action) => {
      state.newsActivesLoading = false;
      state.newsActives = action.payload || null;
    });

    builder.addCase(getLawsuitThunk.pending, (state) => {
      state.newsLawsuitLoading = true;
    });

    builder.addCase(getLawsuitThunk.fulfilled, (state, action) => {
      state.newsLawsuitLoading = false;
      state.newsLawsuit = action.payload || null;
    });
  },
});

export default newsSlice.reducer;
