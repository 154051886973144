import EventsAll from 'src/components/eventsAll/EventsAll';
import Container from 'src/components/ui/shared/container/Container';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import React from 'react';

const EventsAllPage = () => {
  const { t } = useTranslation('common');

  return (
    <Container>
      <Helmet>
        <title>{t('pageTitles.pressCenter')}</title>
      </Helmet>
      <EventsAll />
    </Container>
  );
};
export default EventsAllPage;
