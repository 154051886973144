import NavigationLink from '../navigate-link/NavigateLink';
import { ReactComponent as Arrow } from '../../../../assets/images/icons/arrowGo.svg';
import styles from './linkWithIcon.module.scss';
import { RelativeRoutingType } from 'react-router-dom';

interface Props {
  text: string;
  path: string;
  target?: string;
  relative?: RelativeRoutingType;
}

const LinkWithIcon = ({ text, path, target, relative }: Props) => {
  return (
    <NavigationLink path={path} target={target} relative={relative}>
      <div className={styles.wrapper}>
        <p>{text}</p>
        <Arrow />
      </div>
    </NavigationLink>
  );
};

export default LinkWithIcon;
