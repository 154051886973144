import styles from './input.module.scss';

interface Props {
  type?: string;
  placeholder?: string;
  value?: number | string;
  disabled?: boolean;
  onChange: (value: number | string) => void;
  isValid?: boolean;
  name?: string;
}

const Input = ({
  type = 'text',
  placeholder = '',
  value,
  disabled = false,
  onChange,
  isValid = true,
  name,
}: Props) => (
  <input
    className={
      isValid ? `${styles.input}` : `${styles.input} ${styles.inputNoValid}`
    }
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    disabled={disabled}
    name={name}
  />
);

export default Input;
