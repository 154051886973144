import Accordion from 'src/components/ui/shared/accordion/Accordion';
import MainLink from 'src/components/ui/shared/link/MainLink';
import Loader from 'src/components/ui/shared/loader/Loader';
import routers from 'src/navigation/routers';
import { useAppSelector } from 'src/redux/store';
import styles from './servicesList.module.scss';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ServicesList = () => {
  const { categories, isLoading } = useAppSelector((state) => ({
    categories: state.categoriesServices.categories,
    isLoading: state.categoriesServices.loading,
  }));

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 700) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const renderLinkTitle = (title: string, id: number) => {
    return (
      <Link className={styles.linkTitle} to={`${routers.services}/${id}`}>
        {title}
      </Link>
    );
  };

  return (
    <div className={styles.servicesListBlock}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {categories?.map((item, index) => (
            <Accordion
              key={item.name}
              title={renderLinkTitle(item.name, item.id)}
              height={isMobile ? false : true}
              countList={
                categories[index]?.service_set.length +
                categories[index]?.service_set.length / 2
              }
              border
            >
              <div className={styles.servicesItemList}>
                {categories[index]?.service_set?.map((itemCard) => (
                  <MainLink
                    target='_self'
                    key={itemCard.id}
                    path={`${routers.services}/${categories[index].id}/${itemCard.id}`}
                  >
                    <div>{itemCard.name}</div>
                  </MainLink>
                ))}
              </div>
            </Accordion>
          ))}
        </>
      )}
    </div>
  );
};
export default ServicesList;
