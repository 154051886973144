import { useTranslation } from 'react-i18next';
import NationalizationIcon from '../../../../../assets/images/socialIcon/Nationalization.svg';
import CareerIcon from '../../../../../assets/images/socialIcon/CareerIcon.svg';
import Links from '../../../../../navigation/links';
import LanguageComponent from '../../../shared/entities/languageComponent/LanguageComponent';
import LinksSocial from '../../../shared/entities/linksSocial/LinksSocial';
import sentence from '../../../../../assets/images/socialIcon/SanctionsPortalIcon.svg';
import telegram from '../../../../../assets/images/socialIcon/Telegram.svg';
import styles from './externalHeader.module.scss';
import { useAppSelector } from 'src/redux/store';
import { useLocation } from 'react-router-dom';
import routers from 'src/navigation/routers';
import unlockingAssets from '../../../../../assets/images/socialIcon/UnlockingAssets.svg';

interface Props {
  isVisible?: boolean;
}

interface ILinkItem {
  title: string;
  path: string;
  icon: typeof sentence;
  alt: string;
}

const ExternalHeader = ({ isVisible = true }: Props) => {
  const classHeader = `${styles.externalHeader} ${
    isVisible ? styles.externalHeaderVisible : ''
  }`;

  const contacts = useAppSelector((state) => state.contacts.ruContacts);

  const location = useLocation();
  const { t, i18n } = useTranslation(['common']);

  const isLanguageComponentShowing =
    location.pathname !== routers.assetsUnlocking &&
    location.pathname !== routers.lawsuit;

  const getLinksList = () => {
    let links: ILinkItem[] = [
      {
        title: t('socialLinkHeader.career'),
        path: Links.career,
        icon: CareerIcon,
        alt: t('socialLinkHeader.career'),
      },
    ];

    switch (i18n.language) {
      case 'ru':
        links = [
          {
            title: t('socialLinkHeader.nationalization'),
            path: Links.nationalization,
            icon: NationalizationIcon,
            alt: t('socialLinkHeader.nationalization'),
          },
          {
            title: t('socialLinkHeader.unlockingAssets'),
            path: Links.unlockingAssets || '',
            icon: unlockingAssets,
            alt: t('socialLinkHeader.unlockingAssets'),
          },
          {
            title: t('socialLinkHeader.sancPortal'),
            path: Links.sentence || '',
            icon: sentence,
            alt: t('socialLinkHeader.sancPortal'),
          },
          {
            title: t('socialLinkHeader.telegram'),
            path: contacts?.contacts_set[0]?.telegram || '',
            icon: telegram,
            alt: t('socialLinkHeader.telegram'),
          },
          ...links,
        ];
        break;
    }

    return links;
  };

  return (
    <div className={classHeader}>
      <div className={styles.externalHeaderLink}>
        <LinksSocial links={getLinksList()} />
        {isLanguageComponentShowing && <LanguageComponent />}
      </div>
    </div>
  );
};

export default ExternalHeader;
