import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import Container from 'src/components/ui/shared/container/Container';
import styles from './publications.module.scss';
import { useAppDispatch } from 'src/redux/store';
import { getCurrentPublicationThunk } from 'src/redux/publication/thunk';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const CurrentPublication = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (params.id) {
      dispatch(getCurrentPublicationThunk(params.id));
    }
  }, [dispatch, params.id]);

  return (
    <>
      <Helmet>
        <title>{t('pageTitles.analytics')}</title>
      </Helmet>
      <Container className={styles.content}>CurrentPublication</Container>
    </>
  );
};
export default CurrentPublication;
