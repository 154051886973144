import MainLink from '../../link/MainLink';
import styles from './linkSocial.module.scss';

type Link = {
  title: string;
  path: string;
  icon: any;
  alt: string;
};

interface Props {
  links: Link[];
}

const LinksSocial = ({ links = [] }: Props) => (
  <>
    {links?.map((item, index) => (
      <MainLink path={item.path} key={index}>
        <div className={styles.linkBlock}>
          <img src={item.icon} alt={item.alt} />
          <span>{item.title}</span>
        </div>
      </MainLink>
    ))}
  </>
);

export default LinksSocial;
