import AskLawyer from 'src/components/team/askLawyer/AskLawyer';
import SectionEmployes from 'src/components/team/sectionEmployes/SectionEmployes';
import SectionImage from 'src/components/team/sectionImage/SectionImage';
import Container from 'src/components/ui/shared/container/Container';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import React from 'react';

const Team = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <Helmet>
        <title>{t('pageTitles.team')}</title>
      </Helmet>
      <SectionImage />
      <Container isPaddingTop={false}>
        <SectionEmployes />
        <AskLawyer />
      </Container>
    </>
  );
};

export default Team;
