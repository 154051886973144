/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import Container from 'src/components/ui/shared/container/Container';
import ContainerFlex from 'src/components/ui/shared/containerFlex/ContainerFlex';
import Title from 'src/components/ui/shared/title/Title';
import KeyContacts from 'src/components/ui/widgets/keyContacts/KeyContacts';
import styles from './bounds.module.scss';
import BoundsComponent from 'src/components/boundsComponent/BoundsComponent';
import FilesDownload from 'src/components/ui/widgets/filesDownload/FilesDownload';
import React, { useEffect } from 'react';
import { getObligationServicesThunk } from 'src/redux/categoriesServices/thunk';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import Alexandr from '../../assets/images/team/contact1.png';
import Nadia from '../../assets/images/team/contact2.png';
import { Helmet } from 'react-helmet-async';

const Bounds = () => {
  const { t } = useTranslation(['common', 'team']);
  const dispatch = useAppDispatch();
  const { obligationServices } = useAppSelector((state) => ({
    obligationServices: state.categoriesServices.obligationServices,
    news: state.news.news?.results,
  }));

  useEffect(() => {
    dispatch(getObligationServicesThunk());
  }, []);

  const CONTACT = [
    {
      name: t('team:employeeOne.name'),
      description: t('team:employeeOne.description'),
      email: 'alexander.nektorov@nsplaw.com',
      id: 0,
      image_set: [],
      photo: {
        url: Alexandr,
        id: 0,
        original_filename: '',
        name: '',
        uploaded_at: '',
        modified_at: '',
        mime_type: '',
      },
      position: t('team:employeeOne.position'),
      rating_set: [],
      education: '',
      portfolio: '',
      hobby: '',
      languages: '',
      book_set: [],
      name_ask: 'Александру Некторову',
    },
    {
      name: t('team:employeeTwo.name'),
      description: t('team:employeeTwo.description'),
      email: 'nadezhda.minina@nsplaw.com',
      id: 0,
      image_set: [],
      photo: {
        url: Nadia,
        id: 1,
        original_filename: '',
        name: '',
        uploaded_at: '',
        modified_at: '',
        mime_type: '',
      },
      position: t('team:employeeTwo.position'),
      rating_set: [],
      education: '',
      portfolio: '',
      hobby: '',
      languages: '',
      book_set: [],
      name_ask: 'Надежде Мининой',
    },
  ];

  return (
    <Container className={styles.bondholdersBlock} isPaddingTop={false}>
      <Helmet>
        <title>{t('pageTitles.informationBonds')}</title>
      </Helmet>
      <Title title={`${t('bondholders')}`} />
      <ContainerFlex
        panelLeft={<BoundsComponent />}
        panelRight={
          <>
            <KeyContacts contacts={CONTACT} type='bounds' />
            {/* <NewsBlock className={styles.newsBounds} news={news} /> */}
            <FilesDownload
              className={styles.filesBounds}
              files={obligationServices}
            />
          </>
        }
      />
    </Container>
  );
};
export default Bounds;
